import { FirestoreCollection } from "../constants/FirestoreCollection";
import {
    getAuth
} from "firebase/auth";
import {
    collection,
    doc,
    getFirestore,
    query,
    where,
    getDocs,
    orderBy,
    startAfter,
    updateDoc,
    limit
} from "firebase/firestore";
import { ApplicationDetails } from "../models/ApplicationDetails";
import {QueryConstraint, Timestamp} from "@firebase/firestore";

export default class ApplicationService {
    private static instance: ApplicationService | null = null;

    static getInstance(): ApplicationService {
        if (ApplicationService.instance === null) {
            ApplicationService.instance = new ApplicationService();
        }

        return this.instance as ApplicationService;
    }

    async getApplicationsList(applicationFilter: ApplicationDetails.ApplicationFilter): Promise<ApplicationDetails.ApplicationDetailsResponse | null> {
        const fireStore = getFirestore(getAuth().app);
        const collectionRef = collection(fireStore, FirestoreCollection.applicationDetails);
        const queryConstraint: QueryConstraint[] = [where(applicationFilter.filterBy.key, "==", applicationFilter.filterBy.value), where("province", "==", applicationFilter.province), orderBy(applicationFilter.sortBy.key, applicationFilter.sortBy.direction), limit(applicationFilter.itemsPerPage)]
        const queryRef = applicationFilter.lastDocument === null ?
            query(collectionRef, ...queryConstraint) :
            query(collectionRef, ...queryConstraint, startAfter(applicationFilter.lastDocument));

        return getDocs(queryRef).then(res => {
            if (res.empty) {
                return null;
            }

            // if not empty then go through the list
            const applicationList: Array<ApplicationDetails.ApplicationDetails> = res.docs.map(doc => {
                return { ...doc.data(), applicationId: doc.id } as ApplicationDetails.ApplicationDetails;
            });

            // Update filters with lastDocument value.
            applicationFilter.lastDocument = res.docs[res.size - 1];

            return {
                applicationList: applicationList,
                applicationFilter: applicationFilter
            } as ApplicationDetails.ApplicationDetailsResponse;
        });
    }

    async updateApprovalStatus(applicationId: string, status: ApplicationDetails.ApplicationStatus): Promise<Pick<ApplicationDetails.ApplicationDetails, "status">> {
        const db = getFirestore(getAuth().app);
        const docRef = doc(db, FirestoreCollection.applicationDetails, applicationId);
        const data = {
            status: status,
            updatedDate: Timestamp.now()
        } as Pick<ApplicationDetails.ApplicationDetails, "status" | "updatedDate" | "withdrawnDate" | "issuedDate">;

        switch (status) {
            case "Active":
                data.issuedDate = Timestamp.now();
                break;
            case "NotPaid":
            case "Withdrawn":
                data.withdrawnDate = Timestamp.now();
                break;
        }

        await updateDoc(docRef, data);
        return data;
    }
}
