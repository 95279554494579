import {FirestoreCollection} from "../constants/FirestoreCollection";
import {getAuth} from "firebase/auth";
import {
    collection,
    doc,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    query,
    startAfter,
    updateDoc,
    where
} from "firebase/firestore";
import {QueryConstraint, Timestamp} from "@firebase/firestore";
import {getFunctions, httpsCallable} from "firebase/functions";
import {CloudFunctions} from "../constants/CloudFunctions";
import {AssociationDetails} from "../models/AssociationDetails";

export default class AssociationService {
    private static instance: AssociationService | null = null;

    static getInstance(): AssociationService {
        if (AssociationService.instance === null) {
            AssociationService.instance = new AssociationService();
        }

        return this.instance as AssociationService;
    }

    async getAssociationList(associationFilter: AssociationDetails.AssociationFilter): Promise<AssociationDetails.AssociationDetailsResponse | null> {
        const fireStore = getFirestore(getAuth().app);
        const collectionRef = collection(fireStore, FirestoreCollection.associationsDetails);
        const queryConstraint: QueryConstraint[] = [where(associationFilter.filterBy.key, "==", associationFilter.filterBy.value), where("province", "==", associationFilter.province), orderBy(associationFilter.sortBy.key, associationFilter.sortBy.direction), limit(associationFilter.itemsPerPage)]
        const queryRef = associationFilter.lastDocument === null ?
            query(collectionRef, ...queryConstraint) :
            query(collectionRef, ...queryConstraint, startAfter(associationFilter.lastDocument));

        return getDocs(queryRef).then(res => {
            if (res.empty) {
                return null;
            }

            // if not empty then go through the list
            const associationList: Array<AssociationDetails.AssociationDetailsModel> = res.docs.map(doc => {
                return {...doc.data(), associationId: doc.id} as AssociationDetails.AssociationDetailsModel;
            });

            // Update filters with lastDocument value.
            associationFilter.lastDocument = res.docs[res.size - 1];

            return {
                associationList: associationList,
                associationFilter: associationFilter
            } as AssociationDetails.AssociationDetailsResponse;
        });
    }

    async endFirmAssociationFirestore(associationId: string): Promise<Pick<AssociationDetails.AssociationDetailsModel, "endStatus">> {
        const db = getFirestore(getAuth().app);
        const docRef = doc(db, FirestoreCollection.associationsDetails, associationId);
        const data = {
            endStatus: "Ended",
            updatedDate: Timestamp.now()
        } as Pick<AssociationDetails.AssociationDetailsModel, "endStatus" | "updatedDate">;

        await updateDoc(docRef, data);
        return data;
    }

    async endFirmAssociationFunction(requestModel: AssociationDetails.EndFirmAssociationRequestModel): Promise<AssociationDetails.EndFirmAssociationResponseModel> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.endFirmAssociation)
        const funcResponse = callable(requestModel)

        return funcResponse.then(x => {
            return x.data as AssociationDetails.EndFirmAssociationResponseModel;
        });
    }
}
