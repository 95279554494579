export namespace CommonModels {
    export type ProvinceType = "ALL" | "Limpopo" | "Gauteng" | "WesternCape" | "NorthWest" | "NorthernCape" | "Mpumalanga" | "KwaZuluNatal" | "FreeState" | "EasternCape";
    export const ProvinceTypeMap: Record<ProvinceType, string> = {
        "ALL": "ALL",
        "EasternCape": "Eastern Cape",
        "FreeState": "Free State",
        "Gauteng": "Gauteng",
        "Limpopo": "Limpopo",
        "KwaZuluNatal": "Kwa-Zulu Natal",
        "Mpumalanga": "Mpumalanga",
        "NorthernCape": "Northern Cape",
        "NorthWest": "North West",
        "WesternCape": "Western Cape",
    };

    export interface LpcResponseModel {
        isSuccess: boolean;
        data: object;
        message: string;
        status: number;
    }

}
