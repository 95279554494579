import { FirestoreCollection } from "../constants/FirestoreCollection";
import { getAuth } from "firebase/auth";
import { Timestamp } from "@firebase/firestore";
import {
    collection,
    doc,
    getFirestore,
    query,
    where,
    getDocs,
    orderBy,
    startAfter,
    updateDoc,
    limit
} from "firebase/firestore";
import { AdmissionDetails } from "../models/AdmissionDetails";


export default class AdmissionService {
    private static instance: AdmissionService | null = null;

    static getInstance(): AdmissionService {
        if (AdmissionService.instance === null) {
            AdmissionService.instance = new AdmissionService();
        }

        return this.instance as AdmissionService;
    }

    async getAdmissionsList(admissionFilter: AdmissionDetails.AdmissionFilter): Promise<AdmissionDetails.AdmissionResponse | null> {
        const fireStore = getFirestore(getAuth().app);
        const collectionRef = collection(fireStore, FirestoreCollection.admissionDetails);
        const queryRef = admissionFilter.lastDocument === null ?
            query(collectionRef, where(admissionFilter.filterBy.key, "==", admissionFilter.filterBy.value), orderBy(admissionFilter.sortBy.key, admissionFilter.sortBy.direction), limit(admissionFilter.itemsPerPage)) :
            query(collectionRef, where(admissionFilter.filterBy.key, "==", admissionFilter.filterBy.value), orderBy(admissionFilter.sortBy.key, admissionFilter.sortBy.direction), limit(admissionFilter.itemsPerPage), startAfter(admissionFilter.lastDocument));

        return getDocs(queryRef).then(res => {
            if (res.empty) {
                return null;
            }

            // if not empty then go through the list
            const admissionList: Array<AdmissionDetails.Admission> = res.docs.map(doc => {
                return { ...doc.data(), admissionId: doc.id } as unknown as AdmissionDetails.Admission;
            });

            // Update filters with lastDocument value.
            admissionFilter.lastDocument = res.docs[res.size - 1];

            return {
                admissionList: admissionList,
                admissionFilter: admissionFilter
            } as AdmissionDetails.AdmissionResponse;
        });
    }

    async updateApprovalStatus(admissionId: string, status: AdmissionDetails.AdmissionStatus): Promise<Pick<AdmissionDetails.Admission, "status">> {
        const db = getFirestore(getAuth().app);
        const docRef = doc(db, FirestoreCollection.admissionDetails, admissionId);
        const data = {
            status: status,
            updatedDate : Timestamp.now()
        } as Pick<AdmissionDetails.Admission, "status" | "updatedDate">;

        await updateDoc(docRef, data);
        return data;
    }
}

