import React, {useCallback, useEffect, useRef, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../OtpMfa.css';
import {AppConstants} from "../../../constants/AppConstants";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AdminDetails} from "../../../models/AdminDetails";
import MultiFactorAuthService from "../../../services/MultiFactorAuthService";
import {useLocation, useNavigate} from "react-router-dom";
import {AppRoutes} from "../../../constants/AppRoutes";
import {AppSettings} from "../../../constants/AppSettings";
import {ToastOptionsConst} from '../../../constants/ToastOptionsConst';

const multiFactorAuthService = MultiFactorAuthService.getInstance()

export default function OtpMfaEnroll() {
    // Confirm if they are not enrolled yet or nav tag say new-enroll
    const [seconds, setSeconds] = useState<number>(AppSettings.otpTimeoutInSeconds)
    const countDownRef = useRef<NodeJS.Timer>();
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const location = useLocation()
    const {emailAddress} = location.state;
    const navigate = useNavigate();
    const [formData, setFormData] = useState<AdminDetails.OtpCredentials>({
        otp: ""
    } as AdminDetails.OtpCredentials);

    const startCountDown = useCallback(() => {
        // Clear existing countdown timer
        if (countDownRef.current) clearInterval(countDownRef.current);

        countDownRef.current = setInterval(() => {
            setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
        }, 1000);
    }, []); // Empty dependency array because startCountDown doesn't have any external dependencies

    useEffect(() => {
        startCountDown();
    }, [startCountDown]);

    useEffect(() => {
        if (seconds === 0 && countDownRef.current) {
            clearInterval(countDownRef.current!);
            ErrorMsg(AppConstants.OTPTimedOut);
            navigate(AppRoutes.login, {replace: true});
        }
    }, [navigate, seconds]);

    const handleInputChange = (event: any) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };
    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage("");
        multiFactorAuthService.finishEnrollMultiFactor(formData.otp).then(x => {
            setErrorMessage("");
            SuccessMsg("Successfully enrolled Multi-Factor-Authentication");
            setTimeout(() => {
                navigate(AppRoutes.login)
            }, 4000);
            setLoading(false);
        }).catch(err => {
            ErrorMsg(AppConstants.AuthenticationErrorMap[err.code]);
            setLoading(false);
            setErrorMessage(AppConstants.AuthenticationErrorMap[err.code]);
        })
    }

    return (
        <div className="auth-form">
            <div className="container h-100">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="form-inner">
                        <div className="auth-logo">
                            <img src="/images/LPC-WEB-LOGO 1.jpg" alt=""/>
                        </div>
                        <h4>Admin Enrollment</h4>
                        <form className="text-center">
                            <p className="lead">We have sent you a code</p>
                            <p className="text-center">the code was sent to <span
                                className="email-address">{emailAddress}</span></p>
                            <p className="resend-code-text">Resend code in {seconds}</p>
                            <div className="form-group position-relative text-center">
                                <div className="pinBox">
                                    <input id="otp" type="text" className="pin-input" pattern="[0-9]*" name="otp"
                                           value={formData.otp}
                                           onChange={handleInputChange}
                                           maxLength={6} autoComplete="off" required/>
                                </div>
                                {errorMessage && <p className="otp-incorrect">{errorMessage}</p>}
                            </div>

                            <a href="/2fa/reset" className="resend-code-again mb-3 d-none">Resend again</a>

                            <div className="text-center">
                                <button onClick={handleSubmit} id={AppSettings.recaptchaHtmlId}
                                        className="btn btn-lpc text-uppercase text-center">
                                    {isLoading ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
