import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';

import './AdminToolbox.css'
import {CommonModels} from '../../models/CommonModels'
import {setSelectedProvince} from '../../redux/slice';
import DatePicker from "react-datepicker";
import {addMonths, differenceInMonths} from "date-fns";
import {AppConstants} from "../../constants/AppConstants";

interface Props {
    title: string;
    showDateSelector: boolean;
    setFromDate?: (date: Date) => void;
    setToDate?: (date: Date) => void;
}

const provinceList = CommonModels.ProvinceTypeMap;
const provinceKeyList = Object.keys(CommonModels.ProvinceTypeMap).filter(x => x !== "ALL");
const AdminToolbox = ({title, showDateSelector, setToDate, setFromDate}: Props) => {
    const dispatch = useDispatch();
    const [toSelected, setToSelected] = useState(new Date());
    const [fromSelected, setFromSelected] = useState(new Date());

    const {user} = useSelector((state: any) => state.user);
    const {selectedProvince} = useSelector((state: any) => state.user);

    const handleProvinceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedProvince = event.target.value;
        dispatch(setSelectedProvince(selectedProvince));
    };

    const handleFromDate = (date: any) => {
        setFromSelected(date);

        // if from date is later than to date, set to date to from date
        // OR if the month difference is bigger than 3 then reset
        if (date > toSelected || differenceInMonths(toSelected, date) > AppConstants.ReportsNumberOfMonth - 1) {
            handleToDate(date);
        }

        // ensure this update is last to avoid eachMonthOfInterval error on date interval
        if (setFromDate) setFromDate(date);
    }

    const handleToDate = (date: any) => {
        setToSelected(date);
        if (setToDate) setToDate(date);
    }

    const getMaxDate = (): Date => {
        // we only want a 4-month range, with current included
        return addMonths(fromSelected, (AppConstants.ReportsNumberOfMonth - 1));
    }

    return (
        <div className="select-province-container">
            <div className="d-flex justify-content-between py-2 px-5">
                <p className="navigation-control-title text-start">
                    <span className="fw-bold">
                      {CommonModels.ProvinceTypeMap[selectedProvince as CommonModels.ProvinceType]} /
                    </span>
                    {title}
                </p>
                {showDateSelector && <div className="flex-date-container">
                    <div className="nav-input-label">From</div>
                    <div className="form-group">
                        <DatePicker className="form-control" dateFormat="MMM.yyyy" showMonthYearPicker
                                    selected={fromSelected} onChange={(date) => handleFromDate(date)}/>
                    </div>
                    <div className="nav-input-label">To</div>
                    <div className="form-group">
                        <DatePicker className="form-control" dateFormat="MMM.yyyy" showMonthYearPicker
                                    selected={toSelected}
                                    minDate={fromSelected}
                                    maxDate={getMaxDate()}
                                    onChange={(date) => handleToDate(date)}/>
                    </div>
                </div>}
                <div className="d-flex gap-3">
                    {user.userType === "SuperAdmin" ? (
                        <>
                            <h6 className='nav-input-label'>Province</h6>
                            <select name="" id="selected-province-select" className="lpc-select-input"
                                    value={selectedProvince} onChange={handleProvinceChange}>
                                {provinceKeyList.map((x) => (
                                    <option key={x} value={x}>
                                        {provinceList[x as CommonModels.ProvinceType]}
                                    </option>
                                ))}
                            </select>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default AdminToolbox