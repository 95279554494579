import {AppRoutes} from "../../constants/AppRoutes";

export interface RouteListConstModel {
    icon: string,
    pathname: string,
    title: string
}

export const RouteListConst: Array<RouteListConstModel> = [
    {
        icon: "/assets/icons/home.png",
        pathname: AppRoutes.home,
        title: "Home"
    },
    {
        icon: "/assets/icons/check.png",
        pathname: AppRoutes.ApplicationsList,
        title: "Applications"
    },
    {
        icon: "/assets/icons/invoice.png",
        pathname: AppRoutes.InvoicesList,
        title: "Invoices"
    },
    {
        icon: "/assets/icons/firm-association.png",
        pathname: AppRoutes.AssociationList,
        title: "Associations"
    },
    {
        icon: "/assets/icons/reports.png",
        pathname: AppRoutes.reports,
        title: "Reports"
    },
    {
        icon: "/assets/icons/user.png",
        pathname: AppRoutes.provinceAdminList,
        title: "Province Admin"
    },
    {
        icon: "/assets/icons/contactDetails.svg",
        pathname: AppRoutes.adminEmailsList,
        title: "Admin Emails"
    }

]
