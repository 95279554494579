import { useEffect, useState } from 'react';
import { AppRoutes } from '../../constants/AppRoutes';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { AdminEmail } from "../../models/AdminEmails";
import { AdminEmailsListService } from '../../services/AdminEmailsList';
import AdminToolbox from '../admin-toolbox/AdminToolbox';
import InlineLoading from '../loading/InlineLoading';
import { toast } from 'react-toastify';
import { ToastOptionsConst } from '../../constants/ToastOptionsConst';

export default function AdminEmailsList() {
    const navigate = useNavigate();
    const { user } = useSelector((state: any) => state.user);
    const { selectedProvince } = useSelector((state: any) => state.user);

    const [adminEmails, setAdminEmails] = useState<AdminEmail.AdminContactDetails | null>(null);
    const [editable, setEditable] = useState<boolean>(true); // State to manage whether province selection is editable
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchAdminEmails = async () => {
            setIsLoading(true);
            try {
                const provinceToFetch = user.userType === 'provinceAdmin' ? user.province : selectedProvince;
                const emails = await AdminEmailsListService.getInstance().getAdminEmailsList(provinceToFetch);
                if (emails) {
                    setAdminEmails(emails);
                }
                setIsLoading(false);
            } catch (error) {
                ErrorMsg("Error fetching emails list");
                setIsLoading(false);
            }
        };

        fetchAdminEmails();
    }, [user.userType, selectedProvince, user.province]);

    const ErrorMsg = (msg?: string): void => {
        if (msg) {
            toast.error(msg, ToastOptionsConst.error);
        }
    };

    useEffect(() => {
        // Check if user is province admin, then auto-select their province and disable province selection
        if (user.userType === 'provinceAdmin') {
            setEditable(false); // Disable province selection
        }
    }, [user.userType]);

    const addNewAdminEmail = () => {
        navigate(AppRoutes.adminContactEmails);
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <AdminToolbox title="Admin Emails" showDateSelector={false} />
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                <h1 className="title-crud lpc-page-heading mt-4 text-start">
                                    Admin Emails{' '}
                                    {editable && (
                                        <button type="button" className="btn btn-lpc mx-4" onClick={addNewAdminEmail}>
                                            Add New
                                        </button>
                                    )}
                                </h1>
                                <br />
                                {isLoading && <InlineLoading />}
                                {adminEmails ? (
                                    <div className="table-border-wrap">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className="headers text-start">
                                                    <th>Province</th>
                                                    <th>Emails</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {adminEmails.list.map(({ description, emailAddress }, index) => (
                                                    <tr key={index}>
                                                        <td className="text-start">{adminEmails.province}</td>
                                                        <td className="text-start">{emailAddress}</td>
                                                        <td className="text-start">{description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <>{!isLoading && <div>No admin emails found.</div>}</>
                                )}
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
}
