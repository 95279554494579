import React, {useEffect, useState} from 'react';

import {Link, useNavigate} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import {useSelector} from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginPage.css';
import 'react-toastify/dist/ReactToastify.css';
import UserService from "../../services/UserService";
import {AdminDetails} from "../../models/AdminDetails";
import {AppConstants} from "../../constants/AppConstants";
import {AppRoutes} from "../../constants/AppRoutes";
import { ToastOptionsConst } from '../../constants/ToastOptionsConst';

const userService = UserService.getInstance();

export default function Login() {

    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'LPC - Forgot Password';
        if (user.isLoggedIn) {
            navigate("/");
        }
    }, [user, navigate]);


    const [isLoading, setLoading] = useState(false);

    const [formData, setFormData] = useState<AdminDetails.ForgotPasswordCredentials>({
        email: ""
    } as AdminDetails.ForgotPasswordCredentials);

    const handleInputChange = (event: any) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        // Sign in user
        await userService.sendPasswordResetEmail(formData).then(() => {
            setLoading(false);
            SuccessMsg("Password reset link sent. Kindly check your inbox(or spam).");
        }).catch(e => {
            setLoading(false);
            ErrorMsg(AppConstants.AuthenticationErrorMap[e.code]);
        });

        setLoading(false);
    };

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    return (
        <div className="auth-form">
            <div className="container h-100">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="form-inner">

                        <div className="auth-logo">
                            <img src="/images/LPC-WEB-LOGO 1.jpg" alt=""/>
                        </div>

                        <h4>Admin Forgot Password</h4>
                        <br/>

                        <form onSubmit={handleSubmit}>

                            <div className="form-group">
                                <input id="email" type="email" className="form-control" name="email"
                                       placeholder="Email"
                                       value={formData.email}
                                       onChange={handleInputChange}
                                       required/>
                            </div>

                            <div className="text-center">
                                <button type="submit"
                                        id='recaptcha' className="btn btn-lpc text-uppercase">
                                    {isLoading ? "Submitting..." : "Send reset link"}
                                </button>
                                <div className="mb-3 mt-3">
                                    <Link className="color-main text-uppercase" to={AppRoutes.login}>
                                        GO TO LOGIN
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <ToastContainer/>
        </div>


    );
}
