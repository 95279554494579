import React, {useEffect, useState} from 'react';

import {Link, useNavigate} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import {useSelector} from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginPage.css';
import 'react-toastify/dist/ReactToastify.css';
import UserService from "../../services/UserService";
import {AdminDetails} from '../../models/AdminDetails';
import {AppConstants} from "../../constants/AppConstants";
import MultiFactorAuthService from "../../services/MultiFactorAuthService";
import {multiFactor} from "firebase/auth";
import {AppRoutes} from "../../constants/AppRoutes";
import {AppSettings} from "../../constants/AppSettings";
import {ToastOptionsConst} from '../../constants/ToastOptionsConst';

const userService = UserService.getInstance();
const multiFactorAuthService = MultiFactorAuthService.getInstance();

export default function Login() {

    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'LPC - Login';
        if (user.isLoggedIn) {
            navigate(AppRoutes.dashboard, {replace: true});
        }
    }, [user, navigate]);
    const [isLoading, setLoading] = useState(false);

    const [formData, setFormData] = useState<AdminDetails.SignInCredentials>({
        email: "",
        password: ""
    } as AdminDetails.SignInCredentials);

    const handleInputChange = (event: any) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        // Sign in user
        await userService.signInWithEmailAndPassword(formData).then(async userCredential => {
            // Is SuperAdmin or ProvinceAdmin check
            if (await userService.isCurrentUserAnAdmin()) {
                // check if email is verified
                if (!userCredential.user.emailVerified) {
                    ErrorMsg(AppConstants.VerifyYourEmailAddress);
                    userService.sendEmailVerification();
                    return;
                }

                // check if MFAEnabled - if FALSE then Enroll the user
                const checkMfaEnrollment = multiFactor(userCredential.user).enrolledFactors;
                if (checkMfaEnrollment?.length === 0) {
                    await multiFactorAuthService.startEnrollMultiFactor().then(x => {
                        navigate(AppRoutes.otpMfaEnroll, {
                            state: {email: formData.email}
                        });

                        return;
                    }).catch(err => {
                        ErrorMsg(AppConstants.DefaultErrorMessage);
                    });
                    return;
                }
            } else {
                // user is not an admin - please contact your systems admins for access
                ErrorMsg(AppConstants.UserIsNotAnAdmin);
                setLoading(false);
            }
        }).catch(async err => {
            //MFA is enrolled , then send them OTP and take it from there
            if (err.code === AppConstants.AuthMFARequired) {
                const resolver = multiFactorAuthService.getMfaResolver(err);
                // Pass index [0] to get the first multi-factor hint,
                // otherwise create a dropdown menu to allow the user(if TOTP is enrolled)
                // to choose what multi-factor he wants to use.
                await multiFactorAuthService.startMfaSignIn(resolver.hints[0], resolver.session).then(x => {
                    navigate(AppRoutes.otpMfaLogin, {
                        state: {
                            email: formData.email
                        }
                    });
                    return;
                }).catch(err => {
                    ErrorMsg(AppConstants.DefaultErrorMessage);
                });
                return;
            }
            setLoading(false);
            ErrorMsg(AppConstants.AuthenticationErrorMap[err.code]);
        });

        setLoading(false);
    };

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    return (
        <div className="auth-form">
            <div className="container h-100">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="form-inner">

                        <div className="auth-logo">
                            <img src="/images/LPC-WEB-LOGO 1.jpg" alt=""/>
                        </div>

                        <h4>Administrator Login</h4>
                        <br/>

                        <form>

                            <div className="form-group">
                                <input id="email" type="email" className="form-control" name="email"
                                       placeholder="Email"
                                       value={formData.email}
                                       onChange={handleInputChange}
                                       required/>
                            </div>

                            <div className="form-group">
                                <input id="password" type="password" className="form-control" name="password"
                                       placeholder="Password"
                                       value={formData.password}
                                       onChange={handleInputChange}
                                       required/>
                            </div>

                            <div className="text-center">
                                <button onClick={handleSubmit} id={AppSettings.recaptchaHtmlId}
                                        className="btn btn-lpc text-uppercase">
                                    {isLoading ? "Submitting..." : "Login"}
                                </button>
                                <div className="mb-3 mt-3">
                                    <Link className="color-main text-uppercase" to={AppRoutes.forgotPassword}>
                                        Forgot Password?
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <ToastContainer/>
        </div>


    );
}
