import {AssociationDetails} from "../../models/AssociationDetails";
import {useState} from "react";
import AssociationService from "../../services/AssociationService";
import {format} from "date-fns";
import Modal from 'react-bootstrap/Modal';
import {AppConstants} from "../../constants/AppConstants";

const associationService = AssociationService.getInstance();

interface EndAssociationModalProps {
    firm: AssociationDetails.AssociationDetailsModel,
    removeAssociationFromList: (firm: AssociationDetails.AssociationDetailsModel) => void
}

export default function EndAssociationModal({firm, removeAssociationFromList}: EndAssociationModalProps) {
    const [show, setShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const endFirmAssociation = async () => {
        try {
            setErrorMessage("");
            // end from GS side
            await associationService.endFirmAssociationFirestore(firm.associationId);

            // make call to update MMS
            const req = {
                lpcId: firm.lpcId.toString(),
                date: format(new Date(), "yyyy-MM-dd")
            } as AssociationDetails.EndFirmAssociationRequestModel;
            await associationService.endFirmAssociationFunction(req);

            // trigger a refresh, remove from list
            removeAssociationFromList(firm);
        } catch (ex) {
            setErrorMessage(AppConstants.DefaultErrorMessage);
        }
    }

    return (
        <>
            <button
                type="button"
                className="btn btn-lpc-danger mx-4"
                onClick={handleShow}>
                End Association
            </button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>End Firm Association</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you would like to End Association with {firm.firmName}?<br/>
                    <p className={"lpc-error-msg"}>{errorMessage}</p></Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-lpc-outline"
                        onClick={handleClose}>
                        No
                    </button>
                    <button
                        type="button"
                        className="btn btn-lpc-danger mx-2"
                        onClick={endFirmAssociation}>
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
