import { getFirestore, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { AdminEmail } from "../models/AdminEmails";
import { CommonModels } from "../models/CommonModels";
import { FirestoreCollection } from "../constants/FirestoreCollection";
import { getAuth } from "firebase/auth";

export class AdminEmailService {
  private static instance: AdminEmailService | null = null;

  private constructor() { }

  static getInstance(): AdminEmailService {
    if (AdminEmailService.instance === null) {
      AdminEmailService.instance = new AdminEmailService();
    }
    return AdminEmailService.instance;
  }

  async updateAdminEmail(province: CommonModels.ProvinceType, newContact: AdminEmail.AdmincContactsInfo): Promise<void> {
    try {
      const db = getFirestore(getAuth().app);
      const docRef = doc(db, FirestoreCollection.adminContactDetails, province);

      // Update the document with the new array element using arrayUnion
      const newArrayElement = { emailAddress: newContact.emailAddress, description: newContact.description };
      await updateDoc(docRef, {
        list: arrayUnion(newArrayElement)
      });
    } catch (error: any) {
      throw error;
    }
  }

}
