import React from 'react';
import "./Loading.css";
import InlineLoading from "./InlineLoading";

export default function Loading() {
    return (
        <div className="lds-spinner-container">
           <InlineLoading/>
        </div>
    )
}
