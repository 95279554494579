import React, {useCallback, useEffect, useRef, useState} from 'react';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import ReactApexChart from 'react-apexcharts';
import "react-datepicker/dist/react-datepicker.css";
import 'react-circular-progressbar/dist/styles.css';
import "./Reports.css";
import {ApexOptions} from "apexcharts";
import AdminToolbox from "../admin-toolbox/AdminToolbox";
import {useSelector} from "react-redux";
import ReportService from "../../services/ReportService";
import {ReportDetails} from "../../models/ReportDetails";
import {eachMonthOfInterval, endOfMonth, format, startOfMonth} from "date-fns";

const reportService = ReportService.getInstance();
export default function Reports() {
    const fromDate = useRef<Date>();
    const toDate = useRef<Date>();
    const {selectedProvince} = useSelector((state: any) => state.user);

    // charts data
    const [totalApplicationsReceived, setTotalApplicationsReceived] = useState<number>(0);
    const [totalCertificatesIssued, setTotalCertificatesIssued] = useState<number>(0);
    const [totalAmountOfPayments, setTotalAmountOfPayments] = useState<number>(0);
    const [numberOfPayments, setNumberOfPayments] = useState<number>(0);
    const [numberOfNotPaidApplication, setNumberOfNotPaidApplication] = useState<number>(0);

    // certificates issued and applications per month
    const [barGraphOptions, setBarGraphOptions] = useState<ApexOptions>({
        chart: {
            type: 'bar',
        },
        colors: ['#016533', '#008E47', '#00B259', '#00E874'],
        xaxis: {
            categories: [] as Array<string>,
        },
    });
    const copyOfCertificatesIssuedPerMonth = useRef<Array<number>>([]);
    const [certificatesIssuedPerMonth, setCertificatesIssuedPerMonth] = useState<ApexAxisChartSeries>([
        {
            name: 'Issued Certificates',
            data: [] as Array<number>,
        }
    ]);
    const copyOfApplicationPerMonth = useRef<Array<number>>([]);
    const [applicationsPerMonth, setApplicationsPerMonth] = useState<ApexAxisChartSeries>([
        {
            name: 'Applications',
            data: [],
        }
    ]);

    // percentage of not paid applications
    const [percentageOfNotPaidApplication, setPercentageOfNotPaidApplication] = useState<number>(0);

    const resetPreviousReportData = useCallback(() => {
        // RESET previous data first : Certificates and Applications per month
        copyOfCertificatesIssuedPerMonth.current = [];
        setTotalCertificatesIssued(0);
        setCertificatesIssuedPerMonth(prevState => ([{
            ...prevState[0], // Preserve all other properties
            data: []
        }]));

        copyOfApplicationPerMonth.current = [];
        setTotalApplicationsReceived(0);
        setApplicationsPerMonth(prevState => ([{
            ...prevState[0], // Preserve all other properties
            data: []
        }]));
    }, [])

    const getAllReportsData = useCallback(async (reportFilter: ReportDetails.ReportFilters) => {
        resetPreviousReportData();
        if (reportFilter.fromDate !== undefined && reportFilter.toDate !== undefined) {
            // ALL other per month reports are inside this function
            perMonthReports(reportFilter);

            reportService.getCountOfAwaitingPaymentApplicationsPerProvinceInAGivenPeriod(reportFilter).then(x => {
                // 1 x Applications Awaiting Payment in a given period
                setNumberOfNotPaidApplication(x);
            })

            reportService.getNumberOfPaymentsPerProvinceInAGivenPeriod(reportFilter).then(x => {
                // 1 x Count of payments in a given period
                setNumberOfPayments(x);
            })

            reportService.getTotalPaidPaymentsPerProvinceInAGivenPeriod(reportFilter).then(x => {
                // 1 x TotalAmount of payments in a given period
                setTotalAmountOfPayments(x);
            })
        }
    }, [resetPreviousReportData])

    const getReportFilter = useCallback(() => {
        return {
            province: selectedProvince,
            fromDate: fromDate.current,
            toDate: toDate.current ? endOfMonth(toDate.current as Date) : toDate.current// SET to be END of MONTH
        } as ReportDetails.ReportFilters;
    }, [selectedProvince]);

    const perMonthReports = (reportFilter: ReportDetails.ReportFilters) => {
        if (reportFilter.fromDate > reportFilter.toDate) return; // to avoid passing error to eachMonthOfInterval method

        // Get an array of all months between the start and end dates
        const listOfMonths = eachMonthOfInterval({start: reportFilter.fromDate, end: reportFilter.toDate});

        // Map over the months and format each one to get its name
        const monthNames: Array<string> = [];
        listOfMonths.map(async (month, monthIndex) => {
            const eachMonthFilter = {
                province: reportFilter.province,
                fromDate: startOfMonth(month),
                toDate: endOfMonth(month)
            } as ReportDetails.ReportFilters;

            const monthName = format(month, 'MMMM');
            monthNames.push(monthName);

            // initialize to zeros
            copyOfApplicationPerMonth.current.push(0);
            copyOfCertificatesIssuedPerMonth.current.push(0);

            // 4 x Total Applications per month
            reportService.getTotalApplicationsPerProvincePerMonth(eachMonthFilter).then(tot => {
                const updatedElements = [...copyOfApplicationPerMonth.current] as Array<number>;
                updatedElements[monthIndex] = tot;
                copyOfApplicationPerMonth.current = updatedElements;
                setTotalApplicationsReceived(prevState => prevState + tot);

                setApplicationsPerMonth(prevState => ([{
                    ...prevState[0], // Preserve all other properties
                    data: copyOfApplicationPerMonth.current
                }]));
            })

            // 4 x Issued certificates per month
            reportService.getNumberOfIssuedApplicationsPerProvincePerMonth(eachMonthFilter).then(issuedCount => {
                const updatedElements = [...copyOfCertificatesIssuedPerMonth.current] as Array<number>;
                updatedElements[monthIndex] = issuedCount;
                copyOfCertificatesIssuedPerMonth.current = updatedElements;
                setTotalCertificatesIssued(prevState => prevState + issuedCount);

                setCertificatesIssuedPerMonth(prevState => ([{
                    ...prevState[0], // Preserve all other properties
                    data: copyOfCertificatesIssuedPerMonth.current
                }]));
            })
        });

        setBarGraphOptions(prevState => ({
            ...prevState, // Preserve all other properties
            xaxis: {
                categories: monthNames
            }
        }));
    }

    useEffect(() => {
        const reportFilter = getReportFilter();
        getAllReportsData(reportFilter);
    }, [getAllReportsData, getReportFilter]); // Trigger effect whenever selectedProvince changes

    const handleFromDate = (date: Date) => {
        fromDate.current = date;

        const reportFilter = getReportFilter();
        getAllReportsData(reportFilter);
    }

    const handleToDate = (date: Date) => {
        toDate.current = date;

        const reportFilter = getReportFilter();
        getAllReportsData(reportFilter);
    }

    useEffect(() => {
        // calculate percentage of NOT paid over total without decimals points
        if (totalApplicationsReceived === 0) {
            setPercentageOfNotPaidApplication(0);
            return;
        }
        const percentage = Math.trunc((numberOfNotPaidApplication / totalApplicationsReceived) * 100);
        setPercentageOfNotPaidApplication(percentage);
    }, [numberOfNotPaidApplication, totalApplicationsReceived])

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <AdminToolbox title="Reports" showDateSelector={true} setToDate={handleToDate}
                                      setFromDate={handleFromDate}/>
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                {/* main content will start from here */}
                                <div className="dashboard">

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="box-widget-wrap">

                                                <div className="box-widget-flex t-box">

                                                    <div className="box-widget">
                                                        <div className="box-widget-inner">
                                                            <div className="icon">
                                                                <svg width="24" height="25" viewBox="0 0 24 25"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M8.90851 22.869H15.1692C20.3864 22.869 22.4733 20.7821 22.4733 15.5648V9.30414C22.4733 4.0869 20.3864 2 15.1692 2H8.90851C3.69127 2 1.60437 4.0869 1.60437 9.30414V15.5648C1.60437 20.7821 3.69127 22.869 8.90851 22.869Z"
                                                                        stroke="#DDDDDD" stroke-width="2.43554"
                                                                        stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M15.9517 9.30493H8.12585" stroke="#DDDDDD"
                                                                          stroke-width="2.43554" stroke-linecap="round"
                                                                          stroke-linejoin="round"/>
                                                                    <path d="M15.9517 15.5651H8.12585" stroke="#DDDDDD"
                                                                          stroke-width="2.43554" stroke-linecap="round"
                                                                          stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                            <div className="widget-title">
                                                                <h4>Total Applications received</h4>
                                                            </div>
                                                            <div className="widget-count">
                                                                <span>{totalApplicationsReceived}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="box-widget">
                                                        <div className="box-widget-inner">
                                                            <div className="icon">
                                                                <svg width="19" height="24" viewBox="0 0 19 24"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M18.1364 0H0.863636C0.385937 0 0 0.383036 0 0.857143V23.1429C0 23.617 0.385937 24 0.863636 24H18.1364C18.6141 24 19 23.617 19 23.1429V0.857143C19 0.383036 18.6141 0 18.1364 0ZM17.0568 22.0714H1.94318V16.6875H4.58537C4.89844 17.5661 5.44901 18.3563 6.1804 18.9563C7.11151 19.7196 8.29091 20.1429 9.5 20.1429C10.7091 20.1429 11.8885 19.7223 12.8196 18.9563C13.551 18.3563 14.1016 17.5661 14.4146 16.6875H17.0568V15H13.0517L12.9114 15.6616C12.5875 17.2634 11.1517 18.4286 9.5 18.4286C7.8483 18.4286 6.4125 17.2634 6.08594 15.6616L5.9456 15H1.94318V1.92857H17.0568V22.0714ZM4.31818 7.41964H14.6818C14.8006 7.41964 14.8977 7.32321 14.8977 7.20536V5.91964C14.8977 5.80179 14.8006 5.70536 14.6818 5.70536H4.31818C4.19943 5.70536 4.10227 5.80179 4.10227 5.91964V7.20536C4.10227 7.32321 4.19943 7.41964 4.31818 7.41964ZM4.31818 11.7054H14.6818C14.8006 11.7054 14.8977 11.6089 14.8977 11.4911V10.2054C14.8977 10.0875 14.8006 9.99107 14.6818 9.99107H4.31818C4.19943 9.99107 4.10227 10.0875 4.10227 10.2054V11.4911C4.10227 11.6089 4.19943 11.7054 4.31818 11.7054Z"
                                                                        fill="#DDDDDD"/>
                                                                </svg>
                                                            </div>
                                                            <div className="widget-title">
                                                                <h4>Total Certificates Issued</h4>
                                                            </div>
                                                            <div className="widget-count">
                                                                <span>{totalCertificatesIssued}</span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="box-widget">
                                                        <div className="box-widget-inner">
                                                            <div className="icon">
                                                                <svg width="25" height="25" viewBox="0 0 25 25"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M12.5 0C5.5971 0 0 5.5971 0 12.5C0 19.4029 5.5971 25 12.5 25C19.4029 25 25 19.4029 25 12.5C25 5.5971 19.4029 0 12.5 0ZM13.1222 18.5603L13.1278 19.4448C13.1278 19.5675 13.0273 19.6708 12.9046 19.6708H12.1122C11.9894 19.6708 11.889 19.5703 11.889 19.4475V18.5714C9.41127 18.3873 8.24498 16.9754 8.11942 15.4408C8.10826 15.3097 8.2115 15.1981 8.34263 15.1981H9.6317C9.74051 15.1981 9.83538 15.2762 9.85212 15.3823C9.99442 16.2667 10.6836 16.928 11.9196 17.0926V13.111L11.2305 12.9353C9.7712 12.5865 8.3817 11.6769 8.3817 9.79074C8.3817 7.7567 9.92745 6.66295 11.9029 6.47042V5.54966C11.9029 5.4269 12.0033 5.32645 12.1261 5.32645H12.9102C13.0329 5.32645 13.1334 5.4269 13.1334 5.54966V6.46205C15.0446 6.65458 16.4788 7.77065 16.6462 9.50893C16.6602 9.64007 16.5569 9.75446 16.423 9.75446H15.1702C15.0586 9.75446 14.9637 9.67076 14.9498 9.56194C14.8382 8.74721 14.1853 8.08315 13.1222 7.93806V11.6853L13.8309 11.8499C15.639 12.2963 16.8694 13.1613 16.8694 15.0977C16.8694 17.1987 15.3069 18.3705 13.1222 18.5603ZM10.1172 9.66239C10.1172 10.3711 10.5552 10.9208 11.4983 11.2612C11.6295 11.3142 11.7606 11.356 11.9169 11.4007V7.94085C10.8873 8.07199 10.1172 8.64955 10.1172 9.66239ZM13.3677 13.4347C13.2896 13.418 13.2115 13.3984 13.1222 13.3733V17.1038C14.3108 16.9978 15.1311 16.3449 15.1311 15.2511C15.1311 14.3945 14.6875 13.8365 13.3677 13.4347Z"
                                                                        fill="#DDDDDD"/>
                                                                </svg>
                                                            </div>
                                                            <div className="widget-title">
                                                                <h4>Payments Collected(Rands)</h4>
                                                            </div>
                                                            <div className="widget-count">
                                                                <span>R{totalAmountOfPayments}</span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="box-widget">
                                                        <div className="box-widget-inner">
                                                            <div className="icon">
                                                                <svg width="36" height="25" viewBox="0 0 36 25"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M12.5 0C5.5971 0 0 5.5971 0 12.5C0 19.4029 5.5971 25 12.5 25C19.4029 25 25 19.4029 25 12.5C25 5.5971 19.4029 0 12.5 0ZM13.1222 18.5603L13.1278 19.4448C13.1278 19.5675 13.0273 19.6708 12.9046 19.6708H12.1122C11.9894 19.6708 11.889 19.5703 11.889 19.4475V18.5714C9.41127 18.3873 8.24498 16.9754 8.11942 15.4408C8.10826 15.3097 8.2115 15.1981 8.34263 15.1981H9.6317C9.74051 15.1981 9.83538 15.2762 9.85212 15.3823C9.99442 16.2667 10.6836 16.928 11.9196 17.0926V13.111L11.2305 12.9353C9.7712 12.5865 8.3817 11.6769 8.3817 9.79074C8.3817 7.7567 9.92745 6.66295 11.9029 6.47042V5.54966C11.9029 5.4269 12.0033 5.32645 12.1261 5.32645H12.9102C13.0329 5.32645 13.1334 5.4269 13.1334 5.54966V6.46205C15.0446 6.65458 16.4788 7.77065 16.6462 9.50893C16.6602 9.64007 16.5569 9.75446 16.423 9.75446H15.1702C15.0586 9.75446 14.9637 9.67076 14.9498 9.56194C14.8382 8.74721 14.1853 8.08315 13.1222 7.93806V11.6853L13.8309 11.8499C15.639 12.2963 16.8694 13.1613 16.8694 15.0977C16.8694 17.1987 15.3069 18.3705 13.1222 18.5603ZM10.1172 9.66239C10.1172 10.3711 10.5552 10.9208 11.4983 11.2612C11.6295 11.3142 11.7606 11.356 11.9169 11.4007V7.94085C10.8873 8.07199 10.1172 8.64955 10.1172 9.66239ZM13.3677 13.4347C13.2896 13.418 13.2115 13.3984 13.1222 13.3733V17.1038C14.3108 16.9978 15.1311 16.3449 15.1311 15.2511C15.1311 14.3945 14.6875 13.8365 13.3677 13.4347Z"
                                                                        fill="#DDDDDD"/>
                                                                    <path
                                                                        d="M35.7903 12.5242L28.6041 6.07669C28.549 6.02753 28.4786 6 28.4044 6H26.721C26.5803 6 26.5156 6.1809 26.6221 6.27528L33.2833 12.2528H22.1522C22.0685 12.2528 22 12.3236 22 12.4101V13.5899C22 13.6764 22.0685 13.7472 22.1522 13.7472H33.2814L26.6202 19.7247C26.5137 19.8211 26.5784 20 26.7191 20H28.4596C28.4957 20 28.5318 19.9862 28.5585 19.9607L35.7903 13.4758C35.8561 13.4167 35.9089 13.3435 35.9451 13.2615C35.9813 13.1794 36 13.0902 36 13C36 12.9098 35.9813 12.8206 35.9451 12.7385C35.9089 12.6565 35.8561 12.5834 35.7903 12.5242Z"
                                                                        fill="#DDDDDD"/>
                                                                </svg>
                                                            </div>
                                                            <div className="widget-title">
                                                                <h4>Number of Payments made</h4>
                                                            </div>
                                                            <div className="widget-count">
                                                                <span>{numberOfPayments}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="box-widget-wrap">
                                                <div className="box-widget-flex">

                                                    <div className="box-widget">
                                                        <div className="box-widget-inner">
                                                            <div className="box-title-2">
                                                                <h4>Application Not Paid ({numberOfNotPaidApplication})</h4>
                                                            </div>
                                                            <div className="box-widget-percent-top">
                                                                <span>Paid Application {percentageOfNotPaidApplication ? (100 - percentageOfNotPaidApplication) : 0}%</span>
                                                            </div>
                                                            <div className="box-widget-progress">
                                                                <CircularProgressbar
                                                                    value={percentageOfNotPaidApplication}
                                                                    text={`${percentageOfNotPaidApplication}%`}
                                                                    strokeWidth={15}
                                                                    styles={buildStyles({
                                                                        // Rotation of path and trail, in number of turns (0-1)
                                                                        // rotation: 0.25,

                                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                        // strokeLinecap: 'butt',

                                                                        // Text size
                                                                        // textSize: '16px',

                                                                        // How long animation takes to go from one percentageOfNotPaidApplication to another, in seconds
                                                                        // pathTransitionDuration: 0.5,

                                                                        // Can specify path transition in more detail, or remove it entirely
                                                                        // pathTransition: 'none',

                                                                        // Colors
                                                                        pathColor: `rgba(1, 101, 51, ${percentageOfNotPaidApplication / 100})`,
                                                                        textColor: '#fff',
                                                                        trailColor: '#61D199',
                                                                        // backgroundColor: '#3e98c7',
                                                                    })}/>
                                                            </div>

                                                            <div className="box-widget-percent-bottom">
                                                                <span>NOT Paid {percentageOfNotPaidApplication}%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="box-widget-wrap mt-3">
                                        <div className="box-widget-flex">

                                            <div className="box-widget">
                                                <div className="box-widget-inner">
                                                    <div className="box-title-2">
                                                        <h4>Certificates issue per month</h4>
                                                    </div>

                                                    <div className="box-widget-chart">
                                                        <ReactApexChart options={barGraphOptions}
                                                                        series={certificatesIssuedPerMonth}
                                                                        type="bar"
                                                                        height={350}/>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="box-widget">
                                                <div className="box-widget-inner">
                                                    <div className="box-title-2">
                                                        <h4>Applications received per month</h4>
                                                    </div>

                                                    <div className="box-widget-chart">
                                                        <ReactApexChart options={barGraphOptions}
                                                                        series={applicationsPerMonth}
                                                                        type="bar"
                                                                        height={350}/>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                </div>

                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
