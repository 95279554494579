import {FirestoreCollection} from "../constants/FirestoreCollection";
import {getAuth} from "firebase/auth";
import {
    collection,
    getAggregateFromServer,
    getCountFromServer,
    getFirestore,
    query,
    sum,
    where
} from "firebase/firestore";
import {Timestamp} from "@firebase/firestore";
import {ApplicationDetails} from "../models/ApplicationDetails";
import {PaymentDetails} from "../models/PaymentDetails";
import {ReportDetails} from "../models/ReportDetails";

export default class ReportService {
    private static instance: ReportService | null = null;

    static getInstance(): ReportService {
        if (ReportService.instance === null) {
            ReportService.instance = new ReportService();
        }

        return this.instance as ReportService;
    }

    // get total number of Applications per province, per month
    async getTotalApplicationsPerProvincePerMonth(reportFilter: ReportDetails.ReportFilters): Promise<number> {
        const collectionRef = collection(getFirestore(getAuth().app), FirestoreCollection.applicationDetails);
        const queryRef = query(collectionRef,
            where("province", "==", reportFilter.province),
            where("updatedDate", ">=", Timestamp.fromDate(reportFilter.fromDate)),
            where("updatedDate", "<=", Timestamp.fromDate(reportFilter.toDate)));

        const snapshot = await getCountFromServer(queryRef);
        return snapshot.data().count;
    }

    // get count of UNPAID Applications per province, per month
    async getCountOfAwaitingPaymentApplicationsPerProvinceInAGivenPeriod(reportFilter: ReportDetails.ReportFilters): Promise<number> {
        const collectionRef = collection(getFirestore(getAuth().app), FirestoreCollection.applicationDetails);
        const queryRef = query(collectionRef,
            where("province", "==", reportFilter.province),
            where("status", "==", "NotPaid" as ApplicationDetails.ApplicationStatus),
            where("updatedDate", ">=", Timestamp.fromDate(reportFilter.fromDate)),
            where("updatedDate", "<=", Timestamp.fromDate(reportFilter.toDate)));

        const snapshot = await getCountFromServer(queryRef);
        return snapshot.data().count;
    }

    // get count of PAID/Active Applications per province, per month
    async getNumberOfIssuedApplicationsPerProvincePerMonth(reportFilter: ReportDetails.ReportFilters): Promise<number> {
        const collectionRef = collection(getFirestore(getAuth().app), FirestoreCollection.applicationDetails);
        const queryRef = query(collectionRef,
            where("province", "==", reportFilter.province),
            where("status", "==", "Active" as ApplicationDetails.ApplicationStatus),
            where("updatedDate", ">=", Timestamp.fromDate(reportFilter.fromDate)),
            where("updatedDate", "<=", Timestamp.fromDate(reportFilter.toDate)));

        const snapshot = await getCountFromServer(queryRef);
        return snapshot.data().count;
    }

    // get count of Payments per province, per month
    async getNumberOfPaymentsPerProvinceInAGivenPeriod(reportFilter: ReportDetails.ReportFilters): Promise<number> {
        const collectionRef = collection(getFirestore(getAuth().app), FirestoreCollection.paymentDetails);
        const queryRef = query(collectionRef,
            where("province", "==", reportFilter.province),
            where("updatedDate", ">=", Timestamp.fromDate(reportFilter.fromDate)),
            where("updatedDate", "<=", Timestamp.fromDate(reportFilter.toDate)));

        const snapshot = await getCountFromServer(queryRef);
        return snapshot.data().count;
    }

    // get count of VERIFYING/PAID Payments per province, per month
    async getTotalPaidPaymentsPerProvinceInAGivenPeriod(reportFilter: ReportDetails.ReportFilters): Promise<number> {
        const collectionRef = collection(getFirestore(getAuth().app), FirestoreCollection.paymentDetails);
        const queryRef = query(collectionRef,
            where("province", "==", reportFilter.province),
            where("status", "in", ["Verifying" as PaymentDetails.InvoiceStatus, "Paid" as PaymentDetails.InvoiceStatus]),
            where("updatedDate", ">=", Timestamp.fromDate(reportFilter.fromDate)),
            where("updatedDate", "<=", Timestamp.fromDate(reportFilter.toDate)));

        const snapshot = await getAggregateFromServer(queryRef, {
            totalPaidPayments: sum('totalAmount')
        });

        return snapshot.data().totalPaidPayments;
    }
}
