import { createSlice } from '@reduxjs/toolkit';
import { CommonModels } from '../models/CommonModels';

const initialState = {
    isLoggedIn: false,
    user: {},
    token: '',
    userType: '', // Added userType to store user type
    selectedProvince : "Limpopo" as CommonModels.ProvinceType | null
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setIsLoggedIn: (state) => {
            state.isLoggedIn = true;
        },
        setLogin: (state, action) => {
            state.user = action.payload;            
            state.selectedProvince = action.payload.province === "ALL" ? "Gauteng" : action.payload.province;
            state.userType = action.payload.userType;
        },
        setAuthorizationToken: (state, action) => {
            state.token = action.payload;
        },
        setSelectedProvince: (state, action) => {
            state.selectedProvince = action.payload;
        },
        setLogout: (state) => {
            state.user = {};
            state.token = '';
            state.isLoggedIn = false;
            state.selectedProvince = null;
        }
    }
});

export const { setIsLoggedIn, setLogin, setAuthorizationToken, setLogout, setSelectedProvince } = userSlice.actions;


export default userSlice.reducer;