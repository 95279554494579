import {useEffect, useRef, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import ProtectedRoute from "./ProtectedRoute";
import DashBoard from "./components/dashboard/DashBoard";
import Registration from "./components/registration/Registration";
import Login from "./components/login/LoginPage";
import {firebaseConfig} from "./constants/FirebaseConfigs";
import {AppRoutes} from "./constants/AppRoutes";
import {getApps, initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {setLogout} from "./redux/slice";
import {useDispatch} from "react-redux";
import OtpMfaEnroll from "./components/login/otp-mfa-enroll/OtpMfaEnroll";
import OtpMfaLogin from "./components/login/otp-mfa-login/OtpMfaLogin";
import ForgotPasswordPage from "./components/login/ForgotPasswordPage";
import ApplicationsList from './components/applications-list/ApplicationsList';
import InvoicesList from './components/invoices-list/InvoicesList';
import Reports from './components/reports/Reports';
import Loading from './components/loading/Loading';
import AdmissionsList from './components/admissions/Admissions';
import Layout from './components/layout/Layout';
import {ToastContainer} from "react-toastify";
import AdminContactEmails from './components/contact-details/AdminContactEmails';
import AdminEmailsList from './components/admin-email-list/AdminEmailsList';
import AddProvinceAdmin from "./components/provinceadmin/add-provinceadmin/AddProvinceAdmin";
import ProvinceAdminList from "./components/provinceadmin/list-provinceadmin/ProvinceAdminList";
import AssociationList from "./components/association-list/AssociationList";

function App() {
    const initialRoute = useRef<string>(AppRoutes.login);
    const [initializing, setInitializing] = useState<boolean>(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!getApps() || getApps().length === 0) {  // only initialize firebase app once
            initializeApp(firebaseConfig);
            getAuth().onAuthStateChanged((user) => {
                if (user === null) {
                    initialRoute.current = AppRoutes.login;
                    dispatch(setLogout());
                } else {
                    // TODO : Confirm email verified && MFA enrolled(else redirect to enroll screen)
                    initialRoute.current = AppRoutes.dashboard;
                }

                if (initializing) {
                    setInitializing(false);
                }
            });
        }
    });

    if (initializing) {
        return <Loading></Loading>
    }

    return (
        <BrowserRouter>
            <div>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={
                            <ProtectedRoute>
                                <DashBoard/>
                            </ProtectedRoute>
                        }/>

                        <Route path={AppRoutes.ApplicationsList} element={
                            <ProtectedRoute>
                                <ApplicationsList/>
                            </ProtectedRoute>
                        }/>

                        <Route path={AppRoutes.admissionsList} element={
                            <ProtectedRoute>
                                <AdmissionsList/>
                            </ProtectedRoute>
                        }/>

                        <Route path={AppRoutes.InvoicesList} element={
                            <ProtectedRoute>
                                <InvoicesList/>
                            </ProtectedRoute>
                        }/>

                        <Route path={AppRoutes.AssociationList} element={
                            <ProtectedRoute>
                                <AssociationList/>
                            </ProtectedRoute>
                        }/>

                        <Route path={AppRoutes.reports} element={
                            <ProtectedRoute>
                                <Reports/>
                            </ProtectedRoute>
                        }/>

                        <Route path={AppRoutes.addProvinceAdmin} element={
                            <ProtectedRoute>
                                <AddProvinceAdmin/>
                            </ProtectedRoute>
                        }/>

                        <Route path={AppRoutes.provinceAdminList} element={
                            <ProtectedRoute>
                                <ProvinceAdminList/>
                            </ProtectedRoute>
                        }/>

                        <Route path={AppRoutes.adminContactEmails} element={
                            <ProtectedRoute>
                                <AdminContactEmails/>
                            </ProtectedRoute>
                        }/>

                        <Route path={AppRoutes.adminEmailsList} element={
                            <ProtectedRoute>
                                <AdminEmailsList/>
                            </ProtectedRoute>
                        }/>
                    </Route>

                    <Route path={AppRoutes.otpMfaEnroll} element={<OtpMfaEnroll/>}/>
                    <Route path={AppRoutes.otpMfaLogin} element={<OtpMfaLogin/>}/>
                    <Route path={AppRoutes.Register} element={<Registration/>}/>
                    <Route path={AppRoutes.login} element={<Login/>}/>
                    <Route path={AppRoutes.forgotPassword} element={<ForgotPasswordPage/>}/>
                    <Route path="/*" element={
                        <Navigate to={AppRoutes.dashboard}/>
                    }/>
                </Routes>
                <ToastContainer/>
            </div>
        </BrowserRouter>
    );
}

export default App;


