import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    //   <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
    //  </React.StrictMode>
);

