import {
    getAuth,
    getMultiFactorResolver,
    multiFactor,
    MultiFactorInfo,
    MultiFactorResolver,
    MultiFactorSession,
    PhoneAuthProvider,
    PhoneInfoOptions,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
    User,
    UserCredential
} from 'firebase/auth';
import {AppConstants} from "../constants/AppConstants";
import {AppSettings} from "../constants/AppSettings";

// Code ref : https://codingpr.com/react-and-firebase-multi-factor-authentication/
export default class MultiFactorAuthService {
    private static instance: MultiFactorAuthService | null = null;
    // Used in multi-factor enrollment.
    private verificationId: string | null = null;
    private multiFactorResolver: MultiFactorResolver | null = null;

    static getInstance(): MultiFactorAuthService {
        if (MultiFactorAuthService.instance === null) {
            MultiFactorAuthService.instance = new MultiFactorAuthService();
        }

        return this.instance as MultiFactorAuthService;
    }

    // Starts MFA enrollment and request the verification code.
    async startEnrollMultiFactor(): Promise<void> {
        /**
         * Pass the input id if setting invisible recaptcha,
         * otherwise pass the container id where recaptcha will
         * be launch.
         */
        const recaptchaVerifier = new RecaptchaVerifier(getAuth(),
            AppSettings.recaptchaHtmlId, {size: "invisible"})

        if (getAuth().currentUser && recaptchaVerifier) {
            this.verificationId = await multiFactor(getAuth().currentUser as User)
                .getSession()
                .then(function (multiFactorSession) {

                    // Specify the phone number and pass the MFA session.
                    const phoneInfoOptions = {
                        phoneNumber: getAuth().currentUser?.phoneNumber,
                        session: multiFactorSession,
                    };

                    const phoneAuthProvider = new PhoneAuthProvider(getAuth());

                    // Send SMS verification code.
                    return phoneAuthProvider.verifyPhoneNumber(
                        phoneInfoOptions,
                        recaptchaVerifier
                    );
                })
                .catch(function (error) {
                    // TODO : proper error handling
                    if (error.code === AppConstants.InvalidPhoneNumber) {
                        alert(
                            `Error with phone number formatting. Phone numbers must start with +. ${error}`
                        );
                    } else {
                        alert(`Error enrolling second factor. ${error}`);
                    }
                    throw error;
                });
        } else {
            // The user is not verified.
            return
        }
    }

    async finishEnrollMultiFactor(verificationCode: string): Promise<void> {
        if (this.verificationId && getAuth().currentUser) {
            // Ask user for the verification code. Then:
            const cred = PhoneAuthProvider.credential(this.verificationId, verificationCode);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

            // Complete enrollment.
            return multiFactor(getAuth().currentUser as User)
                .enroll(multiFactorAssertion, "Phone Number 2FA");
        }
    }

    // Sign-in process
    getMfaResolver(error: any): MultiFactorResolver {
        this.multiFactorResolver = getMultiFactorResolver(getAuth(), error)
        return this.multiFactorResolver;
    }

    async startMfaSignIn(multiFactorHint: MultiFactorInfo, session: MultiFactorSession) {
        const recaptchaVerifier = new RecaptchaVerifier(getAuth(), AppSettings.recaptchaHtmlId,
            {size: "invisible"});

        if (multiFactorHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID && recaptchaVerifier) {
            const phoneInfoOptions: PhoneInfoOptions = {
                multiFactorHint: multiFactorHint,
                session: session
            };
            const phoneAuthProvider = new PhoneAuthProvider(getAuth());
            // Send SMS verification code
            this.verificationId = await phoneAuthProvider
                .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
        } else {
            alert("Only phone number second factors are supported.");
        }
    }

    async finishMfaSignIn(verificationCode: string): Promise<UserCredential | null> {
        // Get the SMS verification code sent to the user.
        if (this.verificationId && this.multiFactorResolver) {
            const cred = PhoneAuthProvider.credential(this.verificationId, verificationCode);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

            // Complete sign-in.
            return this.multiFactorResolver
                .resolveSignIn(multiFactorAssertion);
        }

        this.multiFactorResolver = null;
        this.verificationId = null;
        return null;
    }
}


