import {getFunctions, httpsCallable} from 'firebase/functions';
import {AdminDetails} from "../models/AdminDetails";
import {CloudFunctions} from "../constants/CloudFunctions";
import {CommonModels} from "../models/CommonModels";
import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {FirestoreCollection} from "../constants/FirestoreCollection";
import {QueryConstraint} from "@firebase/firestore";
import {CustomClaims} from "../models/CustomClaims";

export default class AdminService {
    private static instance: AdminService | null = null;

    static getInstance(): AdminService {
        if (AdminService.instance === null) {
            AdminService.instance = new AdminService();
        }

        return this.instance as AdminService;
    }

    async addProvinceAdmin(request: AdminDetails.AdminPersonalDetails): Promise<CommonModels.LpcResponseModel | null> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.adminAddProvinceAdmin);
        const funcRef = callable(request);

        return funcRef.then(res => {
            const responseModel = res.data as AdminDetails.AddNewProvinceAdminResponse;
            return responseModel as CommonModels.LpcResponseModel;
        }).catch(error => {
            return null;
        })
    }

    async getProvinceAdminList(province: CommonModels.ProvinceType): Promise<Array<AdminDetails.AdminPersonalDetails> | null> {
        const fireStore = getFirestore(getAuth().app);
        const collectionRef = collection(fireStore, FirestoreCollection.adminPersonalDetails);
        const queryConstraint: QueryConstraint[] = [where("userType", "==", "ProvinceAdmin" as CustomClaims.UserCustomClaimType), where("province", "==", province)]
        const queryRef = query(collectionRef, ...queryConstraint);

        return getDocs(queryRef).then(res => {
            if (res.empty) {
                return null;
            }

            // if not empty then go through the list
            const provinceAdminList: Array<AdminDetails.AdminPersonalDetails> = res.docs.map(doc => {
                return {...doc.data(), uid: doc.id} as AdminDetails.AdminPersonalDetails;
            });

            return provinceAdminList;
        });
    }
}
