import React from 'react';
import {Link, useNavigate} from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import 'react-circular-progressbar/dist/styles.css';
import "./Dashboard.css";
import AdminToolbox from "../admin-toolbox/AdminToolbox";
import {useSelector} from "react-redux";
import {RouteListConst} from "../layout/RoutList.const";

export default function DashBoard() {
    const {user} = useSelector((state: any) => state.user);

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <AdminToolbox title="Home" showDateSelector={false}/>
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                <div className="dashboard">
                                    <div className="dashboard-top-box mb-4 mt-2">
                                        <h3>
                                            Welcome, <br/> {user.firstName + " " + user.surname}
                                        </h3>
                                    </div>
                                    <BusinessRuleCard/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const BusinessRuleCard = () => {
    const navigate = useNavigate();
    return (
        <div className="row">
            {RouteListConst.map((entry) => {
                if (entry.pathname === '/') return <></>;

                return (<div className="col-md-4 mb-4" key={entry.pathname} onClick={() => navigate(entry.pathname)}>
                    <div className={"dashboard-navigation-card"}>
                        <h4>{entry.title}</h4>
                        <Link className="business-rule-card-link"
                              to={entry.pathname}>Go to {entry.title}</Link>
                    </div>
                </div>)
            })}
        </div>)
}

