export const AppRoutes = {
    login: "/login",
    forgotPassword: "/forgot-password",
    dashboard: "/",
    otpMfaEnroll: "/otp-mfa-enroll",
    otpMfaLogin: "/otp-mfa-signin",
    ApplicationsList: "/applicationslist",
    admissionsList: "/admissionslist",
    InvoicesList: "/invoiceslist",
    AssociationList: "/associationlist",
    home: "/",
    reports: '/reports',
    Register: '/register',
    adminContactEmails: '/admin-contact-emails',
    adminEmailsList: '/admin-emails-list',
    addProvinceAdmin: '/add-province-admin',
    provinceAdminList: '/province-admin-list',
};
