import React from 'react';
import { Outlet } from 'react-router-dom';

import './Layout.css';
import SideBar from './sidebar/SideBar';
import Topbar from './topbar/Topbar';

const Layout = () => {
  return (
    <div className="d-flex">
      <div className="side-bar">
        <SideBar />
      </div>
      <div className="top-bar">
        <Topbar />
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
