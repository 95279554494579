import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import { AppRoutes } from "./constants/AppRoutes";

interface Props {
    children: any
}

const ProtectedRoute = ({ children } : Props) => {
    const user = useSelector((state: any) => state.user);
    let location = useLocation();

    if (!user.isLoggedIn) {
        return <Navigate to={AppRoutes.login} state={{ from: location }} replace />
    }
    return children

};

export default ProtectedRoute;
