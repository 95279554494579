import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setLogout} from '../../../redux/slice';
import UserService from "../../../services/UserService";
import './SideBar.css';
import {RouteListConst} from "../RoutList.const";
import {AppRoutes} from "../../../constants/AppRoutes";

const userService = UserService.getInstance();

export default function SideBar() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const logOut = async () => {
        await userService.signOut();
        dispatch(setLogout());
        navigate(AppRoutes.login, {replace: true});
    }

    return (
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
            <div className="app-brand demo mb-5 mt-3">
                <a href="/public#" className="app-brand-link">
                    <span className="app-brand-logo demo">
                        <img src="/images/LPC-WEB-LOGO%201.jpg" alt="" style={{ height: '80px' }} />
                    </span>

                </a>

                <div className="layout-menu-toggle menu-link text-large ms-autod-block d-xl-none">
                    <i className="fa fa-chevron-left align-middle"></i>
                </div>
            </div>

            <div className="menu-inner-shadow"></div>

            <ul className="menu-inner py-1">
                {RouteListConst.map((page) => (
                    <Link to={page.pathname} key={page.title} className={"menu-item " + (page.pathname === location.pathname ? "active" : "" )}>
                        <div className="menu-link">
                            <img className="menu-icon" src={page.icon} alt="Applications" />
                            <span>{page.title}</span>
                        </div>
                    </Link>
                ))}

                <li className="menu-item">
                    <div className="menu-link" style={{cursor: 'pointer'}} onClick={logOut}>
                        <img className="menu-icon" src="/assets/icons/logout.png" alt="logout" />
                        <div style={{color: '#222'}}>Logout</div>
                    </div>
                </li>
            </ul>
        </aside>
    )
}
