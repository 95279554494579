export namespace AppConstants {
    export const EndOfResultPagination: string = "That's it for now...";
    export const DefaultErrorMessage: string = "Oops! Something went wrong!";
    export const UserIsNotAnAdmin: string = "User is NOT an admin, contact your system admin";
    export const VerifyYourEmailAddress: string = "Please verify your email address. Kindly check your inbox.";
    export const OTPTimedOut: string = "OTP has timed out!";
    export const OTPIsInvalid: string = "OTP is invalid. Please enter correct code";
    export const AuthenticationErrorMap: AuthenticationErrorType = {
        "auth/user-not-found": "Email and Password combination not found",
        "auth/wrong-password": "Email and Password combination not found",
        "auth/invalid-login-credentials": "Email and Password combination not found",
        "auth/invalid-email": "Email address is invalid",
        "auth/email-already-in-use": "Email address already registered",
        "auth/weak-password": "Your PASSWORD is weak",
        "auth/second-factor-already-in-use": "Multi-factor already completed. Please login",
        "auth/invalid-verification-code": OTPIsInvalid,
        "auth/operation-not-allowed": DefaultErrorMessage,
    }

    export const AuthMFARequired = "auth/multi-factor-auth-required";

    export const InvalidPhoneNumber = "auth/invalid-phone-number";

    export const ReportsNumberOfMonth = 4;

    type AuthenticationErrorType = Record<string, string>;
}
