import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import './AddProvinceAdmin.css';
import {AdminDetails} from "../../../models/AdminDetails";
import {toast} from "react-toastify";
import {ToastOptionsConst} from "../../../constants/ToastOptionsConst";
import {AppConstants} from "../../../constants/AppConstants";
import AdminService from "../../../services/AdminService";
import {CommonModels} from "../../../models/CommonModels";
import {useSelector} from "react-redux";

const adminService = AdminService.getInstance();
const provinceList = CommonModels.ProvinceTypeMap;
const provinceKeyList = Object.keys(CommonModels.ProvinceTypeMap).filter(x => x !== "ALL");
const fieldIsRequiredMessage = "*Input is required and must be valid";
export default function AddProvinceAdmin() {
    const [isLoading, setLoading] = useState(false);
    const {selectedProvince} = useSelector((state: any) => state.user);
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<AdminDetails.AdminPersonalDetails>();

    useEffect(() => {
        setValue('province', selectedProvince);
    }, [selectedProvince, setValue]);

    const onSubmit: SubmitHandler<AdminDetails.AdminPersonalDetails> = async (data: AdminDetails.AdminPersonalDetails) => {
        setLoading(true);

        // add mobile number area code +27
        if (data.mobileNumber.startsWith('0')){
            data.mobileNumber = '+27' + data.mobileNumber.substring(1);
        }

        if (data.altMobileNumber.startsWith('0')){
            data.altMobileNumber = '+27' + data.altMobileNumber.substring(1);
        }

        adminService.addProvinceAdmin(data).then(res => {
            if (res === null) ErrorMsg('Error adding province admin');

            if (res?.isSuccess) {
                SuccessMsg(res?.message + '');
            } else {
                ErrorMsg(res?.message + '');
            }

            setLoading(false);
        }).catch(err => {
            ErrorMsg(err);
            setLoading(false);
        })
    }

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg?: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                <div className="clear"></div>
                                <h2 className="profile-heading">Add New Province Admin</h2>
                                <form className='simple-form less-margin input-width-90'
                                      onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="form-group col-md-6 mb-4">
                                            <input type="text" className="form-control" placeholder="First Name"
                                                   {...register("firstName", {required: true})}/>
                                            {errors.firstName &&
                                                <span className="lpc-error-msg">{fieldIsRequiredMessage}</span>}
                                        </div>
                                        <div className="form-group col-md-6 mb-4">
                                            <input type="text" className="form-control" placeholder="Surname"
                                                   {...register("surname", {required: true})}/>
                                            {errors.surname &&
                                                <span className="lpc-error-msg">{fieldIsRequiredMessage}</span>}
                                        </div>
                                        <div className="form-group col-md-6 mb-4">
                                            <input type="email" className="form-control" placeholder="Email Address"
                                                   {...register("emailAddress", {required: true})}/>
                                            {errors.surname &&
                                                <span className="lpc-error-msg">{fieldIsRequiredMessage}</span>}
                                        </div>
                                        <div className="form-group col-md-6 mb-4">
                                            <input type="text" className="form-control" placeholder="Identiy No."
                                                   maxLength={13} {...register("identityNumber", {
                                                maxLength: 13,
                                                minLength: 13,
                                                required: true})}/>
                                            {errors.identityNumber &&
                                                <span className="lpc-error-msg">{fieldIsRequiredMessage}</span>}
                                        </div>
                                        <div className="form-group col-md-6 mb-4">
                                            <input type="text" className="form-control" placeholder="Mobile No." maxLength={10}
                                                   {...register("mobileNumber", {
                                                       maxLength: 10,
                                                       minLength: 10,
                                                       required: true})}/>
                                            {errors.mobileNumber &&
                                                <span className="lpc-error-msg">{fieldIsRequiredMessage}</span>}
                                        </div>
                                        <div className="form-group col-md-6 mb-4">
                                            <input type="text" className="form-control"
                                                   placeholder="Alternate Mobile No." maxLength={10}
                                                   {...register("altMobileNumber", {
                                                       maxLength: 10,
                                                       minLength: 10,
                                                       required: true})}/>
                                            {errors.altMobileNumber &&
                                                <span className="lpc-error-msg">{fieldIsRequiredMessage}</span>}
                                        </div>
                                        <div className="form-group col-md-6 mb-4 lpc-select-wrapper">
                                            <select
                                                {...register("province", {required: true})} id="province"
                                                className="form-control lpc-province-select-input"
                                                placeholder="Province">
                                                {provinceKeyList.map(x => (<option key={x}
                                                                                   value={x}>{provinceList[x as CommonModels.ProvinceType]}</option>))}
                                            </select>
                                            {errors.province && <span>{errors.province.message}</span>}
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="d-flex justify-content-between">
                                        <div className="button-groups d-flex flex-column justify-content-around">
                                            <button id="add-province-admin-btn" type="submit"
                                                    className="btn btn-lpc text-uppercase" disabled={isLoading}>
                                                {isLoading ? "Submitting..." : "Add New Province Admin"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                {/* content end here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
