import {FirestoreCollection} from "../constants/FirestoreCollection";
import {getAuth} from "firebase/auth";
import {
    collection,
    doc,
    getFirestore,
    query,
    where,
    getDocs,
    orderBy,
    startAfter,
    updateDoc,
    limit
} from "firebase/firestore";
import {PaymentDetails} from "../models/PaymentDetails";
import {QueryConstraint, Timestamp} from "@firebase/firestore";

export default class PaymentsService {
    private static instance: PaymentsService | null = null;

    static getInstance(): PaymentsService {
        if (PaymentsService.instance === null) {
            PaymentsService.instance = new PaymentsService();
        }

        return this.instance as PaymentsService;
    }

    async getPaymentsList(invoiceFilter: PaymentDetails.InvoiceFilter): Promise<PaymentDetails.PaymentDetailsResponse | null> {
        const fireStore = getFirestore(getAuth().app);
        const collectionRef = collection(fireStore, FirestoreCollection.paymentDetails);
        const queryConstraint: QueryConstraint[] = [where(invoiceFilter.filterBy.key, "==", invoiceFilter.filterBy.value), orderBy(invoiceFilter.sortBy.key, invoiceFilter.sortBy.direction), limit(invoiceFilter.itemsPerPage)]
        const queryRef = invoiceFilter.lastDocument === null ?
            query(collectionRef, ...queryConstraint) :
            query(collectionRef, ...queryConstraint, startAfter(invoiceFilter.lastDocument));

        return getDocs(queryRef).then(res => {
            if (res.empty) {
                return null;
            }

            // if not empty then go through the list
            const invoiceList: Array<PaymentDetails.PaymentDetails> = res.docs.map(doc => {
                return {...doc.data(), userId: doc.id} as PaymentDetails.PaymentDetails;
            });

            // Update filters with lastDocument value.
            invoiceFilter.lastDocument = res.docs[res.size - 1];

            return {
                invoiceList: invoiceList,
                invoiceFilter: invoiceFilter
            } as PaymentDetails.PaymentDetailsResponse;
        });
    }

    async updateApprovalStatus(userId: string, status: PaymentDetails.InvoiceStatus): Promise<Pick<PaymentDetails.PaymentDetails, "status">> {
        const db = getFirestore(getAuth().app);
        const docRef = doc(db, FirestoreCollection.paymentDetails, userId);
        const data = {
            status: status,
            updatedDate: Timestamp.now()
        } as Pick<PaymentDetails.PaymentDetails, "status" | "updatedDate" | "createdDate" | "paidDate">;

        switch (status) {
            case "Outstanding":
                data.createdDate = Timestamp.now();
                break;
            case "Paid":
                data.paidDate = Timestamp.now();
                break;
            case "Initiated":
            case "Verifying":
                data.paidDate = Timestamp.now();
                break;
        }

        await updateDoc(docRef, data);
        return data;
    }
}

