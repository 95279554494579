import { doc, getDoc, getFirestore } from "firebase/firestore";
import { AdminEmail } from "../models/AdminEmails";
import { getAuth } from "firebase/auth";
import { FirestoreCollection } from "../constants/FirestoreCollection";
import { CommonModels } from "../models/CommonModels";

export class AdminEmailsListService {
  private static instance: AdminEmailsListService | null = null;

  static getInstance(): AdminEmailsListService {
    if (AdminEmailsListService.instance === null) {
      AdminEmailsListService.instance = new AdminEmailsListService();
    }

    return this.instance as AdminEmailsListService;
  }

  async getAdminEmailsList(province: CommonModels.ProvinceType): Promise<AdminEmail.AdminContactDetails | null> {
    const docRef = doc(getFirestore(getAuth().app), FirestoreCollection.adminEmailsList, province as string);
    return getDoc(docRef).then(async res => {
      if (res.exists()) {
        return { ...res.data(), province: res.id } as AdminEmail.AdminContactDetails;
      }
      return null;
    })
  }
}
