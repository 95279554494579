import {FirestoreCollection} from "../constants/FirestoreCollection";
import {
    createUserWithEmailAndPassword,
    getAuth,
    getIdTokenResult,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    UserCredential
} from "firebase/auth";
import {collection, doc, getDoc, getFirestore, setDoc} from "firebase/firestore";
import { AdminDetails } from "../models/AdminDetails";
import {AppSettings} from "../constants/AppSettings";
import {CustomClaims} from "../models/CustomClaims";

export default class UserService {
    private static instance: UserService | null = null;

    static getInstance(): UserService {
        if (UserService.instance === null) {
            UserService.instance = new UserService();
        }

        return this.instance as UserService;
    }

    async setPersonalDetail(personalDetail: AdminDetails.RegistrationModel): Promise<AdminDetails.AdminPersonalDetails> {
        const currentUser = getAuth().currentUser;

        // DELETE password from model
        const {password, confirmPassword, ...registrationModelWithoutPass} = personalDetail
        const detail = {
            identityNumber: registrationModelWithoutPass.identityNumber,
            emailAddress: registrationModelWithoutPass.email,
            uid: currentUser?.uid,
            mobileNumber: '+923249639151',
            altMobileNumber: '03249639151',
            userType: 'SuperAdmin'
        } as AdminDetails.AdminPersonalDetails;

        const colRef = collection(getFirestore(getAuth().app), FirestoreCollection.adminPersonalDetails);
        await setDoc(doc(colRef, currentUser?.uid), detail);
        return detail;
    }

    async getPersonalDetail(): Promise<AdminDetails.AdminPersonalDetails | null> {
        const currentUser = getAuth().currentUser;
        const docRef = doc(getFirestore(getAuth().app), FirestoreCollection.adminPersonalDetails, currentUser?.uid as string);
        return getDoc(docRef).then(async res => {
            if (res.exists()) {
                return res.data() as AdminDetails.AdminPersonalDetails;
            }
            return null;
        })
    }

    sendPasswordResetEmail(cred: AdminDetails.ForgotPasswordCredentials): Promise<void> {
        const auth = getAuth();
        return sendPasswordResetEmail(auth, cred.email, {url: AppSettings.adminBaseUrl})
    }

    signInWithEmailAndPassword(cred: AdminDetails.SignInCredentials): Promise<UserCredential> {
        const auth = getAuth();
        return signInWithEmailAndPassword(auth, cred.email, cred.password);
    }

    createUserWithEmailAndPassword(cred: AdminDetails.RegistrationModel): Promise<UserCredential> {
        const auth = getAuth();
        return createUserWithEmailAndPassword(auth, cred.email, cred.password);
    }

    async signOut(): Promise<void> {
        const auth = getAuth();
        return signOut(auth);
    }

    sendEmailVerification(): void {
        const user = getAuth().currentUser;
        if (user) {
            sendEmailVerification(user).then();
        }
    }

    async isCurrentUserAnAdmin(): Promise<boolean> {
        const user = getAuth().currentUser;
        if (user) {
            return await getIdTokenResult(user).then(x => {
                const adminClaims = x?.claims as unknown as CustomClaims.AdminCustomClaims;
                return adminClaims.provinceAdmin || adminClaims.superAdmin;
            })
        } else {
            return false;
        }
    }
}
