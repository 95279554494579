import {useEffect, useState} from 'react';
import {AppRoutes} from '../../../constants/AppRoutes';
import {useNavigate} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import {useSelector} from 'react-redux';
import AdminToolbox from '../../admin-toolbox/AdminToolbox';
import InlineLoading from '../../loading/InlineLoading';
import {ToastOptionsConst} from '../../../constants/ToastOptionsConst';
import AdminService from "../../../services/AdminService";
import {AdminDetails} from "../../../models/AdminDetails";

const adminService = AdminService.getInstance();
export default function ProvinceAdminList() {
    const navigate = useNavigate();
    const {user} = useSelector((state: any) => state.user);
    const {selectedProvince} = useSelector((state: any) => state.user);

    const [adminPersonalDetails, setAdminPersonalDetails] = useState<Array<AdminDetails.AdminPersonalDetails> | null>(null);
    const [editable, setEditable] = useState<boolean>(true); // State to manage whether province selection is editable
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchAdminDetails = async () => {
            setIsLoading(true);
            try {
                const emails = await adminService.getProvinceAdminList(selectedProvince);
                setAdminPersonalDetails(emails);
                setIsLoading(false);
            } catch (error) {
                ErrorMsg("Error fetching admin list");
                setIsLoading(false);
            }
        };

        fetchAdminDetails();
    }, [user.userType, selectedProvince, user.province]);

    const ErrorMsg = (msg?: string): void => {
        if (msg) {
            toast.error(msg, ToastOptionsConst.error);
        }
    };

    useEffect(() => {
        // Check if user is province admin, then auto-select their province and disable province selection
        if (user.userType === 'provinceAdmin') {
            setEditable(false); // Disable province selection
        }
    }, [user.userType]);

    const addNewProvinceAdmin = () => {
        navigate(AppRoutes.addProvinceAdmin);
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <AdminToolbox title="Admin Details" showDateSelector={false}/>
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                <h1 className="title-crud lpc-page-heading mt-4 text-start">
                                    Province Admins List {' '}
                                    {editable && (
                                        <button type="button" className="btn btn-lpc mx-4"
                                                onClick={addNewProvinceAdmin}>
                                            Add New
                                        </button>
                                    )}
                                </h1>
                                <br/>
                                {isLoading && <InlineLoading/>}
                                {adminPersonalDetails ? (
                                    <div className="table-border-wrap">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr className="headers text-start">
                                                <th>Full Names</th>
                                                <th>Emails</th>
                                                <th>User Type</th>
                                                <th>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {adminPersonalDetails.map((details, index) => (
                                                <tr key={index}>
                                                    <td className="text-start">{details.firstName} {details.surname}</td>
                                                    <td className="text-start">{details.emailAddress}</td>
                                                    <td className="text-start">{details.userType}</td>
                                                    <td className="text-start">{details.status}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <>{!isLoading && <div>No admin emails found. Try changing the province above or adding a new one.</div>}</>
                                )}
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        </div>
    );
}
