import {useCallback, useEffect, useState} from 'react';
import ApplicationService from '../../services/ApplicationService';
import {toast, ToastContainer} from 'react-toastify';
import {ApplicationDetails} from '../../models/ApplicationDetails';
import "./ApplicationsList.css";
import {ToastOptionsConst} from '../../constants/ToastOptionsConst';
import {AppConstants} from "../../constants/AppConstants";
import {OrderByDirection} from "@firebase/firestore";
import {AppSettings} from '../../constants/AppSettings';
import InlineLoading from "../loading/InlineLoading";
import {format} from "date-fns";
import {useSelector} from 'react-redux';
import AdminToolbox from '../admin-toolbox/AdminToolbox';

const applicationService = ApplicationService.getInstance();
const filterByOptionList: Array<ApplicationDetails.ApplicationStatus> = ['NotPaid', 'Active', 'Withdrawn', 'Archived'];
const initFilters: ApplicationDetails.ApplicationFilter = {
    sortBy: {key: "firstName", direction: "asc"},
    filterBy: {key: "status", value: "NotPaid"},
    lastDocument: null,
    itemsPerPage: AppSettings.perPage,
    hasMoreItems: true,
    province: null
};
const sortByOptionsDetail: Array<ApplicationDetails.SortByOptionsDetail> = [{
    label: "Alphabetical order",
    direction: "asc",
    key: 'firstName'
},
    {
        label: "Date Received",
        direction: "asc",
        key: 'issuedDate'
    },
    {
        label: "Date Updated",
        direction: "asc",
        key: 'updatedDate'
    }, {
        label: "Status",
        direction: "asc",
        key: 'status'
    }, {
        label: "Numerical Order",
        direction: "asc",
        key: 'applicationNumber'
    }, {
        label: "View All Unapproved",
        direction: "desc",
        key: 'status'
    }];

export default function ApplicationsList() {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [applicationList, setApplicationList] = useState<Array<ApplicationDetails.ApplicationDetails>>([]);
    const [applicationFilter, setApplicationFilter] = useState<ApplicationDetails.ApplicationFilter>(initFilters);
    const {selectedProvince} = useSelector((state: any) => state.user);

    const fetchData = useCallback(async (filter: ApplicationDetails.ApplicationFilter) => {
        setIsLoading(true);
        filter.province = selectedProvince;
        await applicationService.getApplicationsList(filter)
            .then((applicationDetailsResponse: ApplicationDetails.ApplicationDetailsResponse | null) => {
                if (applicationDetailsResponse == null) {
                    // ONLY set to empty if we don't have a list already, else keep it the same
                    setApplicationList(prevState => {
                        if (!prevState) return [];
                        return prevState
                    });

                    setApplicationFilter(prevState => ({...prevState, hasMoreItems: false}));
                    setIsLoading(false);
                    return;
                }

                setApplicationList(prevState => [...prevState, ...applicationDetailsResponse.applicationList]);
                setApplicationFilter(x => (applicationDetailsResponse.applicationFilter));
                setIsLoading(false);
            }).catch((err) => {
                ErrorMsg(AppConstants.DefaultErrorMessage);
            })
    }, [selectedProvince]);

    useEffect(() => {
        const newFilter = {
            ...initFilters,
            lastDocument: null
        } as ApplicationDetails.ApplicationFilter;
        fetchData(newFilter);
    }, [fetchData]);

    const updateStatus = (applicationId: string, status: ApplicationDetails.ApplicationStatus) => {
        applicationService.updateApprovalStatus(applicationId, status).then(() => {
            SuccessMsg("Status updated successfully!");
            // update list
            const filteredList = applicationList.filter(application =>
                application.applicationId !== applicationId && status !== applicationFilter.filterBy.value
            );

            setApplicationList(filteredList);
        }).catch(() => {
            ErrorMsg(AppConstants.DefaultErrorMessage);
        })
    }

    const handleSortByClick = (key: ApplicationDetails.ApplicationSortBy, direction: OrderByDirection) => {
        const newFilter = {
            ...applicationFilter,
            sortBy: {key, direction},
            lastDocument: null,
            hasMoreItems: true
        } as ApplicationDetails.ApplicationFilter;
        setApplicationList([]);
        fetchData(newFilter);
    }

    const handleFilterByClick = (value: ApplicationDetails.ApplicationStatus) => {
        const newFilter = {
            ...applicationFilter,
            filterBy: {key: "status", value},
            lastDocument: null,
            hasMoreItems: true
        } as ApplicationDetails.ApplicationFilter;

        setApplicationList([]);
        fetchData(newFilter);
    }

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg: string) => {
        toast.error(msg, ToastOptionsConst.error);
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <AdminToolbox title="Applications" showDateSelector={false}/>
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 py-2  px-5">
                                <div className="row table-format">
                                    <div className="col-12 col-md-10">
                                        <div className="table-x-scroll">
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>
                                                        <span className="border-left"></span>
                                                        Name &
                                                        Surname
                                                    </th>
                                                    <th>
                                                        LPC Practitioner
                                                        Number
                                                    </th>
                                                    <th>
                                                        Application
                                                        Reference Number
                                                    </th>
                                                    <th>
                                                        Issued
                                                        Date
                                                    </th>
                                                    <th>
                                                        Approval
                                                        Status
                                                    </th>
                                                    <th>
                                                        Withdrawn
                                                        Date
                                                    </th>
                                                    <th>
                                                        Updated
                                                        Date
                                                    </th>
                                                </tr>
                                                </thead>
                                                {selectedProvince && (
                                                    <tbody>
                                                    {applicationList.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.firstName + ' ' + item.surname}</td>
                                                            <td>{item.memberNumber}</td>
                                                            <td>#{item.applicationNumber}</td>
                                                            <td>{item.issuedDate && format(item.issuedDate.toDate(), AppSettings.defaultDateFormat)} </td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <button
                                                                        className={"btn dropdown-toggle " + (item.status === "NotPaid" ? "btn-dark" : item.status === "Withdrawn" ? "btn-danger" : "btn-lpc")}
                                                                        type="button" id="dropdownMenuButton1"
                                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                                        {item.status}
                                                                    </button>
                                                                    <ul className="dropdown-menu"
                                                                        aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <button className="dropdown-item"
                                                                                    onClick={() => updateStatus(item.applicationId, 'Active')}>Active
                                                                            </button>
                                                                            <button className="dropdown-item"
                                                                                    onClick={() => updateStatus(item.applicationId, 'NotPaid')}>NotPaid
                                                                            </button>
                                                                            <button className="dropdown-item"
                                                                                    onClick={() => updateStatus(item.applicationId, 'Withdrawn')}>Withdrawn
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td>{item.withdrawnDate && format(item.withdrawnDate.toDate(), AppSettings.defaultDateFormat)} </td>
                                                            <td>{item.updatedDate && format(item.updatedDate?.toDate(), AppSettings.defaultDateFormat)} </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                )}
                                            </table>
                                            <br/>
                                            {applicationList && applicationList.length > 0 && applicationFilter.hasMoreItems ?
                                                <div className="text-end me-5 mb-3">
                                                    <button type="button" className="btn btn-circled"
                                                            onClick={() => fetchData(applicationFilter)}>
                                                        <i className="fa fa-chevron-down"></i>
                                                    </button>
                                                </div>
                                                : ''}
                                            {isLoading && <InlineLoading/>}
                                            {!applicationFilter.hasMoreItems &&
                                                <p>{AppConstants.EndOfResultPagination}</p>}
                                        </div>
                                        <br/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <div className="d-flex align-items-center">
                                            <div className="svg-icon">
                                                <svg width="43" height="42" viewBox="0 0 43 42" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.0473 28.8175C11.3492 28.8175 10.6511 28.6429 10.0111 28.2745C8.71185 27.5375 7.93613 26.2187 7.93613 24.7448V17.8217C7.93613 17.3175 7.60645 16.4643 7.19921 15.9795L2.31225 10.8404C1.4008 9.94838 0.741455 8.39699 0.741455 7.21405V4.20822C0.741455 1.86172 2.52553 0 4.81385 0H22.0733C24.3228 0 26.1457 1.82292 26.1457 4.07245V6.94257C26.1457 8.45519 25.273 10.1229 24.4198 10.9762L18.7377 15.9988C18.2917 16.3673 17.9232 17.2595 17.9232 17.9188V23.5425C17.9232 24.8806 17.1088 26.374 16.0422 27.014L14.258 28.1773C13.5599 28.6039 12.8037 28.8175 12.0473 28.8175ZM4.81385 2.92829C4.1545 2.92829 3.65034 3.49072 3.65034 4.22763V7.23346C3.65034 7.56313 3.98003 8.39701 4.40667 8.82365L9.40986 14.079C10.1468 14.9905 10.8643 16.4837 10.8643 17.8217V24.7448C10.8643 25.3266 11.2329 25.6177 11.4656 25.734C11.7759 25.9086 12.28 26.0055 12.7066 25.734L14.5101 24.5703C14.7428 24.4345 15.0143 23.8918 15.0143 23.5621V17.938C15.0143 16.4061 15.7707 14.7189 16.8372 13.8075L22.4223 8.86241C22.7714 8.51335 23.2368 7.56313 23.2368 6.94257V4.07245C23.2368 3.4325 22.7132 2.90888 22.0733 2.90888H4.81385V2.92829Z"
                                                        fill="#016533"/>
                                                    <path
                                                        d="M27.4062 41.7129H15.7707C5.24054 41.7129 0.74147 37.2138 0.74147 26.6837V20.8659C0.74147 20.0708 1.40082 19.4115 2.19591 19.4115C2.99101 19.4115 3.65035 20.0708 3.65035 20.8659V26.6837C3.65035 35.6236 6.83073 38.804 15.7707 38.804H27.4062C36.3462 38.804 39.5266 35.6236 39.5266 26.6837V15.0481C39.5266 9.42429 38.2079 6.16632 35.2408 4.47917C34.9305 4.30464 33.9027 3.83926 30.9551 3.37384C30.16 3.25748 29.617 2.50113 29.7527 1.70604C29.8885 0.910945 30.6448 0.367973 31.4205 0.503721C33.9609 0.910964 35.6868 1.37637 36.6759 1.93875C40.5932 4.16889 42.4354 8.33831 42.4354 15.0481V26.6837C42.4354 37.2138 37.9364 41.7129 27.4062 41.7129Z"
                                                        fill="#016533"/>
                                                    <path
                                                        d="M33.224 24.2595H23.5277C22.7326 24.2595 22.0733 23.6001 22.0733 22.805C22.0733 22.0099 22.7326 21.3506 23.5277 21.3506H33.224C34.0191 21.3506 34.6784 22.0099 34.6784 22.805C34.6784 23.6001 34.0191 24.2595 33.224 24.2595Z"
                                                        fill="#016533"/>
                                                    <path
                                                        d="M33.224 32.0167H19.6492C18.8541 32.0167 18.1948 31.3573 18.1948 30.5622C18.1948 29.7671 18.8541 29.1078 19.6492 29.1078H33.224C34.0191 29.1078 34.6784 29.7671 34.6784 30.5622C34.6784 31.3573 34.0191 32.0167 33.224 32.0167Z"
                                                        fill="#016533"/>
                                                </svg>
                                            </div>
                                            <div className="ms-3 sort-text">
                                                Sort By
                                            </div>
                                        </div>
                                        <br/>
                                        <ul className="sort-options">
                                            {sortByOptionsDetail.map((x, index) => {
                                                return (<li key={index}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio"
                                                               name="orderBy"
                                                               key={index}
                                                               id={x.key + index}
                                                               value={applicationFilter.sortBy.key}
                                                               defaultChecked={applicationFilter.sortBy.key === x.key}
                                                               onChange={() => handleSortByClick(x.key, x.direction)}/>
                                                        <label className="form-check-label" htmlFor={x.key + index}>
                                                            {x.label}
                                                        </label>
                                                    </div>
                                                </li>)
                                            })}
                                        </ul>
                                        <div className="d-flex align-items-center">
                                            <div className="svg-icon">
                                                <svg width="47" height="42" viewBox="0 0 47 42" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M45.1266 0H1.87086C0.431352 0 -0.467606 1.5662 0.255086 2.81564L13.9216 26.0095V40.1229C13.9216 41.1612 14.7559 42 15.79 42H31.2074C32.2415 42 33.0758 41.1612 33.0758 40.1229V26.0095L46.7482 2.81564C47.465 1.5662 46.5661 0 45.1266 0ZM28.869 37.7765H18.1285V28.6257H28.8748V37.7765H28.869ZM29.433 23.8978L28.8748 24.8715H18.1226L17.5644 23.8978L5.91323 4.22346H41.0842L29.433 23.8978Z"
                                                        fill="#016533"/>
                                                </svg>
                                            </div>
                                            <div className="ms-3 sort-text">
                                                Filter By
                                            </div>
                                        </div>
                                        <br/>
                                        <ul className="sort-options">
                                            {filterByOptionList.map(x => {
                                                return (
                                                    <li key={x}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio"
                                                                   name="filterBy" key={x}
                                                                   id={x} value={applicationFilter.sortBy.key}
                                                                   defaultChecked={applicationFilter.filterBy.value === x}
                                                                   onClick={() => handleFilterByClick(x)}/>
                                                            <label className="form-check-label" htmlFor={x}>
                                                                {x}
                                                            </label>
                                                        </div>
                                                    </li>)
                                            })}
                                        </ul>

                                    </div>
                                </div>
                                {/* end content here */}
                            </div>
                        </div>
                        <ToastContainer/>
                    </div>
                </div>
            </div>
        </div>
    );
}
