import { useEffect, useState } from "react";
import { AdminEmail } from "../../models/AdminEmails";
import { CommonModels } from "../../models/CommonModels";
import "./AdminContactEmails.css"; // Import your CSS file
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { ToastOptionsConst } from "../../constants/ToastOptionsConst";
import { AdminEmailService } from "../../services/AdminContactEmails";
import { useSelector } from "react-redux";

const adminEmailService = AdminEmailService.getInstance();

export default function AdminContactEmails() {
  const [isLoading, setLoading] = useState(false);
  const provinces = Object.keys(CommonModels.ProvinceTypeMap).filter(x => x !== "ALL");
  const { user } = useSelector((state: any) => state.user);
  const { selectedProvince } = useSelector((state: any) => state.user);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<AdminEmail.AdmincContactsInfo>();

  useEffect(() => {
    setValue('province', selectedProvince);
  }, [selectedProvince, setValue]);

  const SuccessMsg = (msg: string) => {
    toast.success(msg, ToastOptionsConst.success);
  };

  const ErrorMsg = (msg?: string): void => {
    if (msg) {
      toast.error(msg, ToastOptionsConst.error);
    }
  };

  const onSubmit = async (data: AdminEmail.AdmincContactsInfo) => {
    try {
      setLoading(true);
      await adminEmailService.updateAdminEmail(data.province, data);
      setLoading(false);
      SuccessMsg("Email updated successfully!");
      reset()
    } catch (error) {
      setLoading(false);
      ErrorMsg('Error submitting email');
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <div className="layout-page">
            <div className="content-wrapper">
              <div className="d-flex align-items-stretch flex-grow-1 p-0">
                <div className="container-xxl flex-grow-1 container-p-y px-5">
                  <div className="admin-contact-emails-page">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="d-flex align-items-center">
                        <div className="cert-progress"></div>
                        <div className="text-start admin-contact-emails-title">
                          <h4 className="title-crud text-start">
                            Add New Admin Emails{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <form className="simple-form" onSubmit={handleSubmit(onSubmit)}>
                      <div className="d-flex flex-input ">
                        {user.userType === 'SuperAdmin' ? (
                          <div className="flex-input-left w-50">
                            <div className="form-group">
                              <div className="select-container lpc-select-wrapper">
                                <select
                                  {...register('province', { required: true })}
                                  className={`form-control input-lpc pt-0 ${errors.province ? 'input-error' : ''}`}
                                >{provinces.map((province) => (
                                  <option key={province} value={province}>
                                    {province}
                                  </option>
                                ))}
                                </select>
                                {errors.province && <span className="error-message">Province is required</span>}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex-input-left w-50">
                            <div className="form-group">
                              <input
                                {...register('province', { required: true })}
                                disabled // Disable the input field
                                type="text"
                                className="form-control input-lpc pt-0"
                              />
                              {errors.province && <span className="error-message">Province is required</span>}
                            </div>
                          </div>
                        )}
                        <div className="flex-input-right w-50">
                          <div className="form-group">
                            <input
                              {...register('emailAddress', { required: true })}
                              type="email"
                              className={`form-control input-lpc ${errors.emailAddress ? 'input-error' : ''}`}
                              placeholder="Email"
                            />
                            {errors.emailAddress && <span className="error-message">Email is required</span>}
                          </div>
                        </div>
                      </div>
                      <div className="flex-input">
                        <div className="flex-input-left w-100">
                          <div className="form-group">
                            <input
                              {...register('description', { required: true })}
                              type="text"
                              className={`form-control input-lpc ${errors.description ? 'input-error' : ''}`}
                              placeholder="Description"
                            />
                            {errors.description && <span className="error-message flex-input-left">Description is required</span>}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="text-start">
                        <button className="btn btn-lpc btn-round" disabled={isLoading}>
                          {isLoading ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
