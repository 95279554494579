export const FirestoreCollection = {
    adminPersonalDetails: "AdminPersonalDetails",
    twoFaDetails: 'TwoFaAuth',
    applicationDetails: 'Applications',
    associationsDetails: 'Associations',
    admissionDetails: 'Admissions',
    invoiceDetails : 'Payments',
    adminContactDetails : 'AdminContactDetails',
    adminEmailsList : 'AdminContactDetails',    
    paymentDetails : 'Payments'
};
