import React, {useState, useEffect} from "react";
import {Link} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminDetails } from "../../models/AdminDetails";
import UserService from "../../services/UserService";
import {AppConstants} from "../../constants/AppConstants";
import { ToastOptionsConst } from "../../constants/ToastOptionsConst";

const userService = UserService.getInstance();
export default function Register() {

    useEffect(() => {
        document.title = 'LPC - Register';
    }, []);

    // const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [formData, setFormData] = useState<AdminDetails.RegistrationModel>({
        email: "",
        identityNumber: "",
        password: "",
        confirmPassword: "",
    });

    const [validationErrors, setValidationErrors] = useState<AdminDetails.RegistrationModel>({} as AdminDetails.RegistrationModel);

    const handleInputChange = (event: any) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const {email, password, confirmPassword} = formData;
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const errors: AdminDetails.RegistrationModel = {} as AdminDetails.RegistrationModel;


        if (!emailRegex.test(email)) {
            errors.email = "Invalid email address.";
        }


        if (password.length < 6) {
            errors.password = "Password must be at least 6 characters long.";
        }

        if (password !== confirmPassword) {
            errors.confirmPassword = "Passwords do not match.";
        }

        setValidationErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            await userService.createUserWithEmailAndPassword(formData).then(async x => {
                await userService.setPersonalDetail(formData);

                setLoading(false);
                SuccessMsg("Successfully created account!");
            }).catch(err => {
                setLoading(false);
                ErrorMsg(AppConstants.AuthenticationErrorMap[err.code]);
                setValidationErrors(errors);
            });

            setLoading(false);
        }
    };

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    return (

        <div className="auth-form">
            <div className="container h-100">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="form-inner">

                        <div className="auth-logo">
                            <img src="/images/LPC-WEB-LOGO 1.jpg" alt=""/>
                        </div>

                        <h4>Create new Account</h4>

                        <p className='mb-0'>Already registered? <span className='green-font mb-0'
                                                                      id="link-create-acc"><Link to="/login"
                                                                                                 className="color-main">Login</Link></span>
                        </p>

                        <br/>

                        <form onSubmit={handleSubmit}>

                            <div className="row">
                                {/* Email */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Email</label>
                                        <input
                                            type="text"
                                            className={`form-control ${validationErrors.email ? "is-invalid" : ""}`}
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {validationErrors.email && (
                                            <div className="invalid-feedback">
                                                {validationErrors.email}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <br/>
                                {/* LPC Membership Number */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">ID Number</label>
                                        <input
                                            type="text"
                                            name="identityNumber"
                                            className="form-control"
                                            value={formData.identityNumber}
                                            onChange={handleInputChange}
                                            required
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {/* Password */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Password</label>
                                        <input
                                            type="password"
                                            className={`form-control ${validationErrors.password ? "is-invalid" : ""}`}
                                            name="password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {validationErrors.password && (
                                            <div className="invalid-feedback">
                                                {validationErrors.password}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <br/>
                                {/* Confirm Password */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Confirm Password</label>
                                        <input
                                            type="password"
                                            className={`form-control ${validationErrors.confirmPassword ? "is-invalid" : ""}`}
                                            name="confirmPassword"
                                            value={formData.confirmPassword}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {validationErrors.confirmPassword && (
                                            <div className="invalid-feedback">
                                                {validationErrors.confirmPassword}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>


                            <div className="text-start">
                                <button type="submit" className="btn btn-lpc btn-small-radius text-uppercase">
                                    {isLoading ? "Submitting..." : "Sign Up"}
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

            <ToastContainer/>

        </div>


    );
}
